import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Box, Dialog, DialogTitle } from "@mui/material";
import { addParcels } from "Api";
import logo from "Assets/Logo_EROplan_RGB.png";
import { Loader } from "Components";
import ParcelsOverview, {
  ImportParcelTableRow,
} from "Components/importOverview/parcelsOverviewModal";
import { ParcelImportForm } from "Components/parcelImport/parcelImportForm";
import { useQueryParams } from "Hooks/queryParams";
import { NotificationService } from "Services";
import { type AppState } from "Store";
import { getExternalToken } from "Store/externalAccess";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AuthError, ImportSuccess } from "./components";

export const ExternalAccess: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const {
    accessToken: externalAccessToken,
    loading,
    error: authenticationFailed,
  } = useSelector((state: AppState) => state.externalAccess);

  const [showOverview, setShowOverview] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [importData, setImportData] = useState<ImportResponseBody>();

  const queryParams = useQueryParams("t");

  const showImportError = useCallback(() => {
    NotificationService.error(
      t("notification.errorTitle"),
      t("notification.error.parcelimport"),
    );
  }, [t]);

  const handleFormSubmit = useCallback((responseData?: ImportResponseBody) => {
    setImportData(responseData);
    setShowOverview(true);
  }, []);

  const handleOverviewClose = useCallback(() => {
    setShowOverview(false);
  }, []);

  const handleAddSelectedParcels = useCallback(
    async (importID: number, parcels: ImportParcelTableRow[]) => {
      try {
        if (importID) {
          addParcels(
            {
              parcels: parcels.map((p) => ({
                ...p,
                flurstuecksnummern: p.flurstuecksnummern ?? [],
              })),
              importID,
            },
            externalAccessToken,
          );
          handleOverviewClose();
        } else {
          throw new Error("import data not found");
        }
      } catch (_) {
        showImportError();
      }
      setShowImportSuccess(true);
    },
    [externalAccessToken, handleOverviewClose, showImportError],
  );

  useEffect(() => {
    dispatch(getExternalToken(queryParams.t as string));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Dialog open>
        <Box component="img" src={logo} sx={{ m: 2 }} />
        <DialogTitle>{t("parcels.createMultiple")}</DialogTitle>
        {!showImportSuccess && !authenticationFailed && (
          <>
            <ParcelImportForm
              onSuccess={handleFormSubmit}
              externalAccessToken={externalAccessToken}
              hideCustomerAutocomplete={true}
              trimfilenames
            />
          </>
        )}
        {authenticationFailed && <AuthError />}
        {showImportSuccess && <ImportSuccess />}
      </Dialog>
      {showOverview && importData && (
        <ParcelsOverview
          isOpen={showOverview}
          accessToken={externalAccessToken}
          onClose={handleOverviewClose}
          onAddSelectedParcels={handleAddSelectedParcels}
          importData={importData}
          showHelpAfterImport
        />
      )}
    </>
  );
};
