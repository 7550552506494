import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import React, { ComponentProps, useCallback, useState } from "react";

type Props = Omit<ComponentProps<typeof DatePicker>, "value"> & {
  name: string;
  required?: boolean;
};

export const DateInput = React.forwardRef<HTMLInputElement, Props>(
  ({ name, required, ...props }, ref) => {
    const [field, meta, helpers] = useField(name);

    const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
      !field.value ? null : dayjs(field.value),
    );

    const onChange = useCallback(
      (
        dayJsValue: Dayjs | null,
        context: PickerChangeHandlerContext<DateValidationError>,
      ) => {
        setDayJsValue(dayJsValue);
        helpers.setValue(dayJsValue?.valueOf());
        if (props.onChange) {
          props.onChange(dayJsValue, context);
        }
      },
      [helpers, props],
    );

    return (
      <DatePicker
        value={dayJsValue}
        onChange={onChange}
        ref={ref}
        slotProps={{
          textField: {
            ...props.slotProps?.textField,
            required,
            helperText: meta.error,
            fullWidth: true,
          },
          ...props.slotProps,
        }}
        {...props}
      />
    );
  },
);

DateInput.displayName = "DateInput";
