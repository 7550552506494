import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Divider, Fab, Grid2, Paper, Typography } from "@mui/material";
import { Loader, NoOrdersPlaceholder } from "Components";
import { NoSearchResultsPlaceholder } from "Components/noSearchResultsPlaceholder";
import { ROUTES } from "Constants";
import { useTotalArea } from "Hooks/totalArea";
import { useTotalWorkTime } from "Hooks/totalWorkTime";
import { AppState } from "Store";
import { filterOrders } from "Store/planning";
import { FilterType } from "Store/planning/types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FilterPopup, ValuesType } from "./components/filter";
import { OrderItem } from "./components/listItem";

interface ISidebar {
  toggleSidebar: () => void;
  setDroppableContainerEl: (element: HTMLDivElement) => void;
}

export const Sidebar: React.FC<ISidebar> = ({
  toggleSidebar,
  setDroppableContainerEl,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedUnit = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );
  const { orders, displayedDateRange, filters, loading, updateJobLoading } =
    useSelector((state: AppState) => state.planning);

  const ordersContainerRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = React.useState<number | false>(false);

  useEffect(() => {
    if (ordersContainerRef.current) {
      setDroppableContainerEl(ordersContainerRef.current);
    }
  }, [setDroppableContainerEl]);

  const onSubmit = useCallback(
    (values: ValuesType) => {
      const { useOrderDateRange, grapeVarietyColor, ...filterValues } = values;
      const filters: FilterType = {
        ...filterValues,
        grapeVarietyColor:
          grapeVarietyColor && grapeVarietyColor.length > 0
            ? grapeVarietyColor
            : undefined,
        ordersDateRange: useOrderDateRange ? displayedDateRange : undefined,
      };

      dispatch(filterOrders(filters));
    }, //eslint-disable-next-line react-hooks/exhaustive-deps
    [displayedDateRange],
  );

  const onReset = useCallback(() => {
    if (filters) dispatch(filterOrders({}));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const allJobs = useMemo(
    () => orders.flatMap((order) => order.jobDetails?.jobs || []),
    [orders],
  );

  const totalArea = useTotalArea(allJobs);
  const totalWorkTime = useTotalWorkTime(allJobs);

  const handleExpandChange = useCallback(
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const orderItems = useMemo(
    () =>
      orders.map(
        (order) =>
          order.jobDetails && (
            <Grid2 key={order._id} size={12}>
              <OrderItem
                expanded={expanded}
                onChange={handleExpandChange}
                order={order}
              />
            </Grid2>
          ),
      ),
    [expanded, handleExpandChange, orders],
  );

  const unplannedOrdersCount: number = useMemo(
    () => orders.filter((order) => order.jobDetails).length,
    [orders],
  );

  const filterCount: number = useMemo(
    () =>
      Object.values(filters || {}).filter(
        (value) =>
          value !== "" &&
          value !== undefined &&
          value !== null &&
          !(Array.isArray(value) && value.length === 0),
      ).length,
    [filters],
  );

  const createOrder = useCallback(() => {
    navigate(ROUTES.MAIN.ORDERS, { state: { create: true } });
  }, [navigate]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "16px 0",
          transition: "width 0.5s",
          position: "relative",
        }}
      >
        <Box padding="0 16px">
          <Grid2 container rowGap={2}>
            <Grid2 alignSelf={"center"} size={10}>
              <Typography variant="button">
                {t("components.calendar.sidebar.unplannedOrders", {
                  count: unplannedOrdersCount,
                })}
              </Typography>
            </Grid2>
            <Grid2 size={2}>
              <FilterPopup
                onSubmit={onSubmit}
                onReset={onReset}
                filterCount={filterCount}
              />
            </Grid2>
          </Grid2>
          <Fab
            sx={{
              position: "absolute",
              top: 43,
              right: -16,
              zIndex: 1000,
            }}
            onClick={toggleSidebar}
            size="small"
          >
            <ChevronLeft fontSize="small" />
          </Fab>
        </Box>
        <Divider sx={{ marginTop: 1, marginBottom: 3 }} />
        <Box
          ref={ordersContainerRef}
          sx={{ overflowY: "auto" }}
          height="100%"
          padding="2px 8px 2px 16px"
        >
          {(loading || updateJobLoading) && <Loader></Loader>}
          <Grid2 container rowSpacing={2}>
            {!loading && orderItems}
          </Grid2>
          {unplannedOrdersCount === 0 &&
            (filterCount === 0 ? (
              <NoOrdersPlaceholder onAddClick={createOrder} />
            ) : (
              <NoSearchResultsPlaceholder
                title={t("components.calendar.sidebar.searchPlaceholderTitle")}
                text={t("components.calendar.sidebar.searchPlaceholderText")}
              />
            ))}
        </Box>
        <Box padding="0 16px">
          <Grid2 container rowSpacing={2}>
            <Grid2 size={12}>
              <Box borderTop={1} borderColor={"#ced4da"} pt={2} pl={1}>
                <Typography variant="body2">
                  <b>{`${t("general.labels.totalArea")}:`}</b>{" "}
                  {`${totalArea.toFixed(2)} ${UnitSymbol[selectedUnit]}`}
                </Typography>
                <Typography variant="body2">
                  <b>{`${t("general.labels.totalWorktime")}:`}</b>{" "}
                  {`${totalWorkTime}`}
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Paper>
    </>
  );
};
