import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { SqMeter } from "@ero/app-common/util/Units";
import { Grid2, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";

type ParcelSizeAndCountProps = {
  parcelsSize: number | undefined;
  parcelsCount: number | undefined;
};

export const ParcelSizeAndCount: React.FC<ParcelSizeAndCountProps> = ({
  parcelsSize,
  parcelsCount,
}) => {
  const [t] = useTranslation();

  const { unitOfMeasurement } = useSelector(
    (state: AppState) => state.auth.companyData,
  );

  if (!parcelsSize || !parcelsCount) {
    return <></>;
  }

  return (
    <Grid2 size={12}>
      <Typography variant="body2">
        {parcelsSize &&
          convertAreaToCustom(parcelsSize as SqMeter, unitOfMeasurement) + " "}
        {parcelsSize && UnitSymbol[unitOfMeasurement]}
        {parcelsSize && "; "}
        {parcelsCount + " "}
        {parcelsCount && t("general.parcel", { count: parcelsCount })}
      </Typography>
    </Grid2>
  );
};
