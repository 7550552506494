import { ParcelHistoryResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { ChevronRight } from "@mui/icons-material";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ROUTES } from "Constants";
import { useUserTitle } from "Hooks/userTitle";
import { AppState } from "Store";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface IProps {
  item: ParcelHistoryResponseBody["jobHistory"][0];
}

export const HistoryItem: React.FC<IProps> = ({ item }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const language = useSelector((state: AppState) => state.auth.selectedLang);

  const endDate = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        dateStyle: "medium",
        timeStyle: "short",
      })
        .format(new Date(item.end))
        .replace(",", ""),
    [item.end, language],
  );

  const driverName = useUserTitle(item.employees[0]);

  const driverAvatar = useMemo(() => {
    if (item.employees.length == 0) {
      return undefined;
    }
    const driver = item.employees[0];
    return driver.avatar;
  }, [item.employees]);

  const onItemClick = useCallback(() => {
    const route = [ROUTES.MAIN.ORDERS, "/", item.order].join("");
    navigate(route, { state: { jobId: item._id } });
  }, [item._id, item.order, navigate]);

  return (
    <ListItem
      onClick={onItemClick}
      sx={{ justifyContent: "space-between", width: "100%" }}
    >
      <ListItemButton>
        <ListItemAvatar sx={{ marginRight: 4 }}>
          <Stack direction={"column"} alignItems={"center"}>
            <Avatar src={driverAvatar} />
            <Typography variant="caption">{driverName}</Typography>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          secondary={t("parcels.jobHistory.completedOn", { date: endDate })}
        >
          {item.name}
        </ListItemText>
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};
