import { Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";

type GrapeVarietiesProps = {
  gemarkungen: string[] | undefined;
};

export const Gemarkungen: React.FC<GrapeVarietiesProps> = ({ gemarkungen }) => {
  const gemarkungenText = useMemo(() => {
    let joined = gemarkungen?.slice(0, 3).join(", ") ?? "";
    if (gemarkungen !== undefined && gemarkungen?.length > 3) {
      joined += ` + ${gemarkungen.length - 3}`;
    }
    return joined;
  }, [gemarkungen]);

  return (
    <Grid2 size={12}>
      <Typography variant="caption">{gemarkungenText}</Typography>
    </Grid2>
  );
};
