import { CustomerOutside } from "@ero/app-common/models";
import { PickBySpec } from "@ero/app-common/util/Spec";

export const customersListSpec = [
  ["companyName"],
  ["email"],
  ["category", "_id"],
  ["category", "name"],
  ["category", "meta"],
  ["firstName"],
  ["label"],
  ["lastName"],
  ["mobile"],
  ["mainContact", "_id"],
  ["mainContact", "firstName"],
  ["mainContact", "lastName"],
  ["mainContact", "description"],
  ["mainContact", "phone"],
  ["mainContact", "mobile"],
  ["mainContact", "email"],
  ["creationDate"],
  ["city"],
  ["street"],
  ["zipCode"],
  ["phone"],
  ["mobile"],
  ["internalId"],
  ["label"],
  ["persons", "_id"],
  ["persons", "firstName"],
  ["persons", "lastName"],
  ["persons", "description"],
  ["persons", "phone"],
  ["persons", "mobile"],
  ["persons", "email"],
  ["invoices"],
  ["notes"],
] as const;

export type LocalCustomerType = PickBySpec<
  CustomerOutside,
  typeof customersListSpec
>;
