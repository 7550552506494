import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { PasswordInput } from "Components/controls/PasswordInput";
import { successToast } from "Services";
import { type AppState } from "Store";
import { resetMeta, updateEmployeePassword } from "Store/employees";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getValidationSchema,
  initialValues,
  type ValuesType,
} from "./formConfig";

interface IChangePasswordModal {
  open: boolean;
  onClose: () => void;
  employeeId: number;
}

export const ChangePasswordModal: React.FC<IChangePasswordModal> = ({
  open,
  onClose,
  employeeId,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { loading, editPasswordSuccess } = useSelector(
    (store: AppState) => store.employees,
  );

  useEffect(() => {
    if (editPasswordSuccess) {
      successToast(t("notification.success.changePassword"));
      dispatch(resetMeta());
      onClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPasswordSuccess, onClose]);

  const onSubmit = useCallback(
    (values: ValuesType) => {
      dispatch(updateEmployeePassword(employeeId, values.password));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeId],
  );

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>{t("changePassword.title")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        validationSchema={getValidationSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, isValid, dirty }) => (
          <>
            <DialogContent dividers>
              <Form autoComplete="off">
                <Stack spacing={3}>
                  <PasswordInput
                    name="password"
                    label={t("changePassword.newPassword")}
                  />
                  <PasswordInput
                    name="confirmPassword"
                    label={t("changePassword.confirmPassword")}
                  />
                </Stack>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                disabled={!isValid || !dirty || loading}
                onClick={submitForm}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
