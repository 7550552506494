import { GridColumnVisibilityModel, GridRowId } from "@mui/x-data-grid";
import { ConfirmDialog } from "Components";
import { CreateOrderDialog } from "Components/createOrderDialog/createOrderDialog";
import { BaseDataTable } from "Components/tableV2/baseDataTable/BaseDataTable";
import { ROUTES } from "Constants";
import { type AppState } from "Store";
import { ordersSlice, sagaActions } from "Store/orders";
import { Dayjs } from "dayjs";
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderFilters } from "./components/orderFilters";
import { useColumns } from "./ordersConfig";

export type OrderRouterState = {
  filters: ReturnType<
    typeof sagaActions.setListMetaAndFilters
  >["payload"]["listFilters"];
  create?: boolean;
  limit?: number;
  offset?: number;
};

export const Orders: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const routerState = state as OrderRouterState | undefined;

  const columns = useColumns();

  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState(false);

  const [createdOrderDate, setCreatedOrderDate] = useState<number>();

  const {
    list: list,
    listLoading,
    listMeta,
    listFilters,
    updateLoading,
    columnVisibilityModel,
  } = useSelector((state: AppState) => state.orders);

  const paginationModel = useMemo(
    () => ({
      page: listMeta.offset / listMeta.limit,
      pageSize: listMeta.limit,
    }),
    [listMeta.limit, listMeta.offset],
  );

  const onCloseCreateOrderDialog = useCallback(
    (orderDateRange?: { start: Dayjs; end: Dayjs }) => {
      setIsCreateOrderModalOpen(false);
      if (orderDateRange) {
        setCreatedOrderDate(orderDateRange.start.unix() * 1000);
      } else if (routerState?.create) {
        navigate(-1);
      }
    },
    [navigate, routerState?.create],
  );

  const handleTableRowsDelete = useCallback((ids: readonly GridRowId[]) => {
    if (ids.length === 0) {
      return;
    }
    dispatch(sagaActions.deleteOrders(ids.map((id) => +id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRouterState = useCallback(
    (state: OrderRouterState) => {
      navigate(ROUTES.MAIN.ORDERS, {
        state,
        replace: true,
      });
    },
    [navigate],
  );

  const toolbarProps: ComponentProps<typeof BaseDataTable>["toolbarProps"] =
    useMemo(
      () => ({
        onAddClick: () => setIsCreateOrderModalOpen(true),
        onDeleteClick: handleTableRowsDelete,
        filters: [
          <OrderFilters
            key="orderFilters"
            routerState={routerState}
            setRouterState={setRouterState}
          />,
        ],
      }),
      [handleTableRowsDelete, routerState, setRouterState],
    );

  const confirmDialogIsOpen = useMemo(
    () => !listLoading && !updateLoading && createdOrderDate !== undefined,
    [createdOrderDate, listLoading, updateLoading],
  );

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      const offset = page * pageSize;
      const limit = pageSize;
      setRouterState({
        filters: listFilters,
        limit,
        offset,
      });
    },
    [listFilters, setRouterState],
  );

  const handleQuickSearch = useCallback(
    (search: string) => {
      setRouterState({
        filters: { ...listFilters, search },
        limit: listMeta.limit,
        offset: listMeta.offset,
      });
    },
    [listFilters, listMeta.limit, listMeta.offset, setRouterState],
  );

  const onRowClick = useCallback(
    (id: number) => {
      navigate(`${ROUTES.MAIN.ORDERS}/${id}`);
    },
    [navigate],
  );

  const onChangeColumnsView = useCallback(
    (values: GridColumnVisibilityModel) => {
      dispatch(ordersSlice.actions.setColumnVisibilityModel(values));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseChangeScreenConfirmModal = useCallback(() => {
    setCreatedOrderDate(undefined);
    if (routerState?.create) {
      navigate(-1);
    }
  }, [navigate, routerState?.create]);

  const changeScreenHandler = useCallback((): void => {
    navigate(`${ROUTES.MAIN.PLANNING2}`, {
      state: {
        initialDate: createdOrderDate,
      },
    });
    setCreatedOrderDate(undefined);
  }, [createdOrderDate, navigate]);

  useEffect(() => {
    if (routerState?.create) {
      setIsCreateOrderModalOpen(true);
    }
  }, [routerState]);

  useEffect(() => {
    dispatch(
      sagaActions.setListMetaAndFilters({
        listMeta: {
          offset: routerState?.offset ?? 0,
          limit: routerState?.limit ?? listMeta.limit,
        },
        listFilters: routerState?.filters ?? {},
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.limit, routerState?.offset, routerState?.filters]);

  return (
    <>
      <BaseDataTable
        searchQuery={routerState?.filters?.search}
        columnVisibilityModel={columnVisibilityModel}
        paginationModel={paginationModel}
        columns={columns}
        handlePagination={handlePagination}
        handleQuickSearch={handleQuickSearch}
        loading={listLoading || updateLoading}
        maxCount={listMeta.maxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={onRowClick}
        rows={list}
        toolbarProps={toolbarProps}
      />
      {isCreateOrderModalOpen && (
        <CreateOrderDialog
          open={isCreateOrderModalOpen}
          createAction={sagaActions.createOrder}
          onClose={onCloseCreateOrderDialog}
        />
      )}
      <ConfirmDialog
        isOpen={confirmDialogIsOpen}
        onApprove={changeScreenHandler}
        onClose={onCloseChangeScreenConfirmModal}
        title={t("orders.created")}
        text={t("orders.navigateToCalendar")}
      />
    </>
  );
};
