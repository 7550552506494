import { Add } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type NoOrdersPlaceholderProps = {
  onAddClick: () => void;
};

export const NoOrdersPlaceholder: React.FC<NoOrdersPlaceholderProps> = ({
  onAddClick,
}) => {
  const [t] = useTranslation();

  return (
    <Box mt={4} textAlign="center">
      <Fab color="primary" size="small" onClick={onAddClick}>
        <Add />
      </Fab>
      <Typography variant="h6" sx={{ paddingTop: 2 }}>
        {t("components.calendar.sidebar.noOrdersTitle")}
      </Typography>
      <Typography variant="body1">
        {t("components.calendar.sidebar.noOrdersText")}
      </Typography>
    </Box>
  );
};
