import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";

import { IMPORT_SOURCE_RLP } from "@ero/app-common/enums/parcelImport";
import { SqMeter } from "@ero/app-common/util/Units";
import {
  ImportedParcel,
  ImportResponseBody,
} from "@ero/app-common/v2/routes/models/import";
import { Help } from "@mui/icons-material";
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { ImportHelpModal } from "Components/importHelpModal/importHelpModal";
import { useStaticDropdownValues } from "Hooks/dropdownValues";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { NotificationService } from "Services";
import { Coordinate, OptionType } from "Types";
import { ImportMap } from "./components/map/ImportMap";
import { usePolygonOperations } from "./components/map/polygonOperations/usePolygonOperations";
import { POLYGON_EDIT_MODE } from "./components/map/utils";
import { ParcelsTable } from "./components/table/table";

export type ImportParcelTableRow = ImportedParcel & {
  _id: number;
  sizeManual?: SqMeter;
};

const getParcelName = (
  newRow: GridValidRowModel,
  oldRow: GridValidRowModel,
  grapeVarieties: OptionType[],
) => {
  const newGrapeVarietyName =
    grapeVarieties
      .find((option) => option.value === newRow.grapeVariety)
      ?.label.split(" (")[0] ?? "";
  const oldGrapeVarietyName =
    grapeVarieties
      .find((option) => option.value === oldRow.grapeVariety)
      ?.label.split(" (")[0] ?? "";

  if (oldRow.name != newRow.name) {
    return newRow.name;
  }
  if (newRow.name.includes(oldGrapeVarietyName)) {
    return newRow.name.replace(oldGrapeVarietyName, newGrapeVarietyName);
  } else {
    if (newRow.name.match(/#\d+$/)?.index > 0) {
      return `${newGrapeVarietyName} ${newRow.name.replace(/#\d+$/, "")}`;
    } else {
      return `${newGrapeVarietyName} ${newRow.name}`;
    }
  }
};

type ParcelsOverviewProps = {
  isOpen: boolean;
  onClose: () => void;
  importData: ImportResponseBody;
  onAddSelectedParcels: (
    importID: number,
    selectedParcels: ImportParcelTableRow[],
  ) => void;
  accessToken?: string;
  showHelpAfterImport?: boolean;
};

const ParcelsOverview: React.FC<ParcelsOverviewProps> = ({
  isOpen,
  onClose,
  importData,
  onAddSelectedParcels,
  accessToken,
  showHelpAfterImport = false,
}) => {
  const [t] = useTranslation();

  const { values: grapeVarietyOptions } = useStaticDropdownValues(
    "grapeVariety",
    accessToken,
  );

  const mapRef = useRef<google.maps.Map>();
  const tableRef = useGridApiRef();
  const splitLineRef = useRef<google.maps.Polyline | null>(null);

  const [parcels, setParcels] = useState(
    importData.data.map((parcel, index) => ({
      ...parcel,
      _id: index + 1,
    })),
  );

  const [selectedParcelIds, setSelectedParcels] = useState<number[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [editParcelId, setEditParcelId] = useState<GridRowId>();
  const [parcelIdsToMerge, setParcelIdsToMerge] = useState<number[]>([]);
  const [polygonEditMode, setPolygonEditMode] = useState<POLYGON_EDIT_MODE>(
    POLYGON_EDIT_MODE.NONE,
  );
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const openHelpDialog = useCallback(() => setShowHelpDialog(true), []);
  const closeHelpDialog = useCallback(() => setShowHelpDialog(false), []);

  useEffect(() => {
    if (isOpen && showHelpAfterImport) setShowHelpDialog(true);
  }, [isOpen, showHelpAfterImport]);

  const parcelInEditMode: boolean = useMemo(
    () =>
      parcels.filter((parcel) => editParcelId && parcel._id === +editParcelId)
        .length > 0,
    [editParcelId, parcels],
  );

  const handleAddSelectedParcels = useCallback(() => {
    onAddSelectedParcels(
      importData._id,
      parcels.filter((parcel: ImportParcelTableRow) =>
        selectedParcelIds.includes(parcel?._id ?? -1),
      ),
    );
    setSelectedParcels([]);
  }, [importData._id, onAddSelectedParcels, parcels, selectedParcelIds]);

  const handleScrollTable = useCallback(
    (rowIndex: number) => {
      const currentApiRef = tableRef?.current;

      if (rowIndex >= 0 && currentApiRef) {
        const pageSize =
          currentApiRef.state.pagination.paginationModel.pageSize;

        const currentPage = currentApiRef.state.pagination.paginationModel.page;

        const pageIndex = Math.floor(rowIndex / pageSize);

        if (pageIndex !== currentPage) {
          currentApiRef.setPage(pageIndex);
        }

        setTimeout(() => {
          currentApiRef.scrollToIndexes({ rowIndex, colIndex: 0 });
        }, 100);
      }
    },

    [tableRef],
  );

  const fitMap = useCallback((coordinates: Coordinate[]) => {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((coord) => bounds.extend(coord));
    mapRef.current?.fitBounds(bounds);
  }, []);

  const onCancelEditParcelData = useCallback((id: GridRowId) => {
    setRowModesModel((val) => ({
      ...val,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setEditParcelId(undefined);
    setParcelIdsToMerge([]);
    setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
  }, []);

  const onEditParcelData = useCallback(
    (id: GridRowId) => {
      setRowModesModel((val) => ({
        ...val,
        [id]: { mode: GridRowModes.Edit },
      }));
      if (editParcelId !== undefined) {
        onCancelEditParcelData(editParcelId);
      }
      setEditParcelId(id);
    },
    [editParcelId, onCancelEditParcelData],
  );

  const onRowClick = useCallback(
    ({ row }: GridRowParams<ImportParcelTableRow>) => {
      onEditParcelData(row._id);
      if (row.shape) {
        fitMap(row.shape);
      }
    },
    [fitMap, onEditParcelData],
  );

  const onRowSelect = useCallback(
    (ids: readonly GridRowId[]) => {
      setSelectedParcels(ids as number[]);
    },
    [setSelectedParcels],
  );

  const onEndEditParcelData = useCallback(
    (id: GridRowId | undefined, ignoreModifications?: boolean) => {
      setRowModesModel(() =>
        parcels.reduce(
          (acc, curr) => ({
            ...acc,
            [curr._id]: { mode: GridRowModes.View, ignoreModifications },
          }),
          {},
        ),
      );
      setEditParcelId(undefined);
      setParcelIdsToMerge([]);
      if (id !== undefined) {
        setSelectedParcels((val) => (val.includes(+id) ? val : [...val, +id]));
      }
    },
    [parcels],
  );

  const handleUpdateRow = useCallback(
    (
      newRow: GridValidRowModel,
      oldRow: GridValidRowModel,
    ): GridValidRowModel => {
      let name = newRow.name;
      if (importData.importSource === IMPORT_SOURCE_RLP.FLORLP) {
        name = getParcelName(newRow, oldRow, grapeVarietyOptions);
      }
      setParcels((val) =>
        val.map((parcel: ImportParcelTableRow) => {
          if (parcel._id !== newRow._id) {
            return parcel;
          }
          return {
            ...newRow,
            _id: newRow._id,
            name,
            shape: newRow.shape,
            sizeField: newRow.sizeField,
            size: newRow.size > 0 ? newRow.size : undefined,
            rowAmount: newRow.rowAmount > 0 ? newRow.rowAmount : undefined,
            originalParcelIds: newRow.originalParcelIds,
          };
        }),
      );

      return {
        ...newRow,
        name,
        size: newRow.size > 0 ? newRow.size : undefined,
        rowAmount: newRow.rowAmount > 0 ? newRow.rowAmount : undefined,
      };
    },
    [grapeVarietyOptions, importData.importSource],
  );

  const handleUpdateRowError = useCallback((error: any) => {
    NotificationService.error(
      "Process row update failed",
      JSON.stringify(error),
    );
  }, []);

  const onParcelClick = useCallback(
    (id: number) => {
      switch (polygonEditMode) {
        case POLYGON_EDIT_MODE.NONE: {
          if (editParcelId === undefined) {
            onEditParcelData(id);
            const rowIndex = parcels.findIndex((parcel) => parcel._id === id);
            handleScrollTable(rowIndex); // table is zero based
          }
          break;
        }
        case POLYGON_EDIT_MODE.MERGE: {
          const parcelAlreadySelected =
            parcelIdsToMerge.find((parcel) => parcel === id) !== undefined;
          setParcelIdsToMerge(
            parcelAlreadySelected
              ? [...parcelIdsToMerge.filter((parcel) => parcel !== id)]
              : [...parcelIdsToMerge, id],
          );
          break;
        }
      }
    },
    [
      polygonEditMode,
      editParcelId,
      onEditParcelData,
      parcels,
      handleScrollTable,
      parcelIdsToMerge,
    ],
  );

  const onSplitComplete = useCallback(
    (newParcelData: ImportParcelTableRow[], oldParcelId: number) => {
      setSelectedParcels(
        selectedParcelIds.filter((val) => val !== oldParcelId),
      );
      setParcels(newParcelData);
      setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
      onEndEditParcelData(undefined, true);
    },
    [onEndEditParcelData, selectedParcelIds, setParcels],
  );

  const onMergeComplete = useCallback(
    (newParcelData: ImportParcelTableRow[], mergedParcelId: number) => {
      setSelectedParcels((curr) => [...curr, mergedParcelId]);
      setParcels(newParcelData);
      setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
      onEndEditParcelData(undefined, true);
    },
    [onEndEditParcelData, setParcels],
  );

  const enableSplitMode = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.SPLIT);
  }, []);

  const enableMergeMode = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.MERGE);
    if (editParcelId !== undefined) {
      setParcelIdsToMerge([+editParcelId]);
    }
  }, [editParcelId]);

  const cancelOperation = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
    setParcelIdsToMerge([]);
  }, []);

  const polygonOperations = usePolygonOperations(
    parcels,
    editParcelId,
    parcelIdsToMerge,
    splitLineRef,
    onSplitComplete,
    onMergeComplete,
  );

  const showSelectParcelsAlert = useMemo(
    () => selectedParcelIds.length == 0,
    [selectedParcelIds.length],
  );

  const showSaveParcelAlert = useMemo(
    () => parcelInEditMode && selectedParcelIds.length > 0,
    [parcelInEditMode, selectedParcelIds.length],
  );

  const helpText = useMemo(
    () => (
      <Typography variant="body1">
        <ReactMarkdown>
          {t("parcels.upload.importHelpModal.text")}
        </ReactMarkdown>
      </Typography>
    ),
    [t],
  );

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={false}>
        <DialogTitle sx={{ pb: 0 }}>
          {t("parcels.upload.overview.title")}
        </DialogTitle>
        <CloseButton onClose={onClose} />
        <DialogContent>
          <Grid2
            container
            spacing={2}
            sx={{ pt: 1, pl: 2, width: "100%", height: "80vh" }}
          >
            <Grid2 size={6}>
              <ImportMap
                mapRef={mapRef}
                splitLineRef={splitLineRef}
                parcels={parcels}
                selectedParcelIds={selectedParcelIds}
                editParcelId={editParcelId}
                mergeParcelIds={parcelIdsToMerge}
                onParcelClick={onParcelClick}
                editMode={polygonEditMode}
                polygonOperations={{
                  ...polygonOperations,
                  enableMergeMode,
                  enableSplitMode,
                  cancelOperation,
                }}
              />
            </Grid2>
            <Grid2 sx={{ height: "100%" }} size={6}>
              <Box
                sx={
                  showSelectParcelsAlert || showSaveParcelAlert
                    ? { height: "calc(100% - 48px)" }
                    : { height: "100%" }
                }
              >
                <ParcelsTable
                  apiRef={tableRef}
                  grapeVarietyOptions={grapeVarietyOptions}
                  parcelData={parcels}
                  handleUpdateRow={handleUpdateRow}
                  handleUpdateRowError={handleUpdateRowError}
                  selectedParcelIds={selectedParcelIds}
                  rowModesModel={rowModesModel}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelect}
                  editRowId={editParcelId}
                  actions={{
                    onEditClick: onEditParcelData,
                    onSaveClick: onEndEditParcelData,
                    onCancelClick: onCancelEditParcelData,
                  }}
                  disableActionButtons={
                    polygonEditMode !== POLYGON_EDIT_MODE.NONE
                  }
                />
              </Box>
              {showSelectParcelsAlert && (
                <Alert severity="warning">
                  {t(
                    "parcels.upload.overview.alertNotification.selectParcelsToImport",
                  )}
                </Alert>
              )}
              {showSaveParcelAlert && (
                <Alert severity="info">
                  {t(
                    "parcels.upload.overview.alertNotification.saveCurrentParcel",
                  )}
                </Alert>
              )}
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={openHelpDialog} startIcon={<Help />} color="info">
            {t("general.navigation.help")}
          </Button>
          <Button
            onClick={handleAddSelectedParcels}
            disabled={selectedParcelIds.length == 0 || parcelInEditMode}
            variant="contained"
          >
            {t("parcels.upload.overview.addParcels", {
              count: selectedParcelIds.length,
            })}
          </Button>
        </DialogActions>
      </Dialog>
      <ImportHelpModal
        open={showHelpDialog}
        onClose={closeHelpDialog}
        videoUrl="https://www.youtube-nocookie.com/embed/0lPeW03bgXc?rel=0&si=zTc0_d_jKgQOCMss"
        helpText={helpText}
      />
    </>
  );
};

export default ParcelsOverview;
