import { Grid2 } from "@mui/material";
import { FullscreenContext } from "Contexts/fullScreenContext";
import React from "react";
import { Calendar } from "./components/calendar/calendar";
import { Sidebar } from "./components/sidebar/sidebar";
import { EventDraggingContext } from "./contexts/EventDraggingContext";

export const PlanningV2: React.FC = () => {
  return (
    <FullscreenContext
      sx={(theme) => ({
        height: "100%",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <EventDraggingContext>
        <Grid2
          container
          columns={18}
          sx={{
            height: "100%",
          }}
        >
          <Grid2 size={4} sx={{ height: "100%" }}>
            <Sidebar />
          </Grid2>
          <Grid2 size={0.3}></Grid2>
          <Grid2 size={"grow"} sx={{ height: "100%" }}>
            <Calendar />
          </Grid2>
        </Grid2>
      </EventDraggingContext>
    </FullscreenContext>
  );
};
