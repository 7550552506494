import { AddJobRequestBody } from "@ero/app-common/v2/routes/models/job";
import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getParcels } from "Api";
import { CloseButton } from "Components/closeButton/closeButton";
import { errorToast } from "Services";
import { AppState } from "Store";
import { createJob } from "Store/orderDetails";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ParcelButtons } from "./components/parcelButtons";
import { SubmitButton } from "./components/submitButton";
import type { FormikValues } from "./helpers";
import { ModalContent } from "./helpers";
import { MainForm } from "./screens/Form";
import { ParcelSelectionMap } from "./screens/ParcelSelectionMap";
import { getValidationSchema } from "./validationConfig";

interface AddJobModal {
  open: boolean;
  onClose: () => void;
}

export const AddJobModal: React.FC<AddJobModal> = ({ open, onClose }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [shownContent, setShownContent] = useState<ModalContent>(
    ModalContent.FORM,
  );

  const order = useSelector((state: AppState) => state.orderDetails.order);

  const [parcels, setParcels] = useState<ParcelResponseBody[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const { data: parcels } = await getParcels({
        customer: order?.customer._id,
        limit: 999,
      });
      setParcels(parcels);
    };
    if (order?.customer._id !== undefined) {
      fetch();
    }
  }, [order?.customer._id]);

  const initialValues: FormikValues = useMemo(
    () => ({
      parcel: "",
      service: "",
      notes: undefined,
    }),
    [],
  );

  const handleFormSubmit = async (values: FormikValues) => {
    try {
      if (order !== undefined) {
        const config: AddJobRequestBody = {
          order: order?._id,
          parcel: values.parcel as number,
          service: values.service as number,
          notes: values.notes,
        };
        dispatch(createJob(config));
        onClose();
      } else {
        throw Error("No order available!");
      }
    } catch (error) {
      errorToast(undefined, undefined, error);
    }
  };

  const parcelMapButtons: React.ReactNode = useMemo(
    () => (
      <ParcelButtons
        goBackToMainContent={() => {
          setShownContent(ModalContent.FORM);
        }}
      />
    ),
    [],
  );

  const content = useMemo(() => {
    switch (shownContent) {
      case ModalContent.PARCELSMAP:
        return (
          <ParcelSelectionMap
            navigate={setShownContent}
            parcels={parcels}
            name="parcel"
          />
        );
      case ModalContent.FORM:
      default:
        return (
          order?.customer._id && (
            <MainForm
              navigate={setShownContent}
              customerId={order?.customer._id}
            />
          )
        );
    }
  }, [shownContent, setShownContent, order?.customer._id, parcels]);

  const close = useCallback(() => {
    setShownContent(ModalContent.FORM);
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md" scroll="body">
      <DialogTitle>{t("orders.details.overview.addJob")}</DialogTitle>
      <CloseButton onClose={close} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={getValidationSchema()}
        validateOnMount={true}
      >
        <Form>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            {shownContent === ModalContent.FORM ? (
              <SubmitButton />
            ) : (
              parcelMapButtons
            )}
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
