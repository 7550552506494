import {
  OrderResponseBody,
  OrdersRequestQuery,
} from "@ero/app-common/v2/routes/models/order";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OrdersState = {
  list: OrderResponseBody[];
  listMeta: {
    offset: number;
    limit: number;
    maxCount: number;
    lastInternalOrderId: number;
  };
  listLoading: boolean;
  listFilters: Omit<OrdersRequestQuery, "offset" | "limit" | "slim">;
  updateLoading: boolean;
  columnVisibilityModel: GridColumnVisibilityModel;
};

const initialState: OrdersState = {
  list: [],
  listMeta: {
    offset: 0,
    limit: 25,
    maxCount: 9999,
    lastInternalOrderId: 0,
  },
  listLoading: false,
  listFilters: {},
  updateLoading: false,
  columnVisibilityModel: {},
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.listLoading = action.payload;
    },
    setList: (
      state,
      action: PayloadAction<{
        list: OrderResponseBody[];
        maxCount: number;
        lastOrderId: number;
      }>,
    ) => {
      state.list = action.payload.list;
      state.listMeta.maxCount = action.payload.maxCount;
      state.listMeta.lastInternalOrderId = action.payload.lastOrderId;
      state.listLoading = false;
    },
    setListItem: (state, action: PayloadAction<OrderResponseBody>) => {
      const index = state.list.findIndex(
        (entry) => entry._id === action.payload._id,
      );
      state.list[index] = action.payload;
    },
    setColumnVisibilityModel: (
      state,
      action: PayloadAction<GridColumnVisibilityModel>,
    ) => {
      state.columnVisibilityModel = action.payload;
    },
    setListMeta: (
      state,
      action: PayloadAction<{
        offset?: number;
        limit?: number;
      }>,
    ) => {
      state.listMeta = { ...state.listMeta, ...action.payload };
    },
    setUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.updateLoading = action.payload;
    },
    setListFilters: (
      state,
      action: PayloadAction<
        Partial<Omit<OrdersRequestQuery, "limit" | "offset">>
      >,
    ) => {
      state.listFilters = action.payload;
    },
  },
});
