import { type Milliseconds } from "@ero/app-common/util/Milliseconds";
import { Card, CardContent, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

interface IReleaseNoteCard {
  item: string;
  date: Milliseconds;
  text: string;
}

export const ReleaseNoteCard: React.FC<IReleaseNoteCard> = ({
  item,
  date,
  text,
}) => {
  const [_, i18n] = useTranslation();

  const dateString = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.language, {
        dateStyle: "medium",
      }).format(new Date(date)),
    [date, i18n.language],
  );

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography color="text.secondary" gutterBottom>
          {dateString}
        </Typography>
        <Typography variant="h5">{item}</Typography>
        <ReactMarkdown>{text}</ReactMarkdown>
      </CardContent>
    </Card>
  );
};
