import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { FormikValues } from "Components/parcelModal/validationConfig";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { Coordinate } from "Types";
import { getPolygonArea } from "Utils";
import { DRAWINGMODE } from "../utils";

export const useGeoHelpers = (drawingMode: DRAWINGMODE) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const handleMarkerDrag = (e: google.maps.MapMouseEvent) => {
    const newCoord = e.latLng?.toJSON();

    setFieldValue("position", newCoord);
  };

  const handleMarkerSet = useCallback(
    async (newCoord: Coordinate) => {
      await setFieldValue("position", newCoord);
      await setFieldValue("size", 0);
      await setFieldValue("sizeField", SIZE_FIELD.SIZE_AUTOMATIC);

      // reset polygon on first marker set
      if (values.shape) {
        await setFieldValue("shape", null);
        await setFieldValue("isClosedPolygon", false);
      }
    },
    [setFieldValue, values.shape],
  );

  const handlePolyVertexSet = useCallback(
    async (newCoord: Coordinate) => {
      const startNewPolygon =
        values.isClosedPolygon ||
        values.shape === undefined ||
        values.shape === null ||
        values.shape.length === 0;
      if (startNewPolygon) {
        await setFieldValue("isClosedPolygon", false);
        await setFieldValue("shape", [newCoord]);
        if (values.position) {
          await setFieldValue("position", null);
        }
        return;
      } else {
        if (!values.shape) {
          return;
        }
        const continueWithNewPolygon = values.shape.length > 0;
        if (continueWithNewPolygon) {
          const firstPolygonCoordinateClicked =
            newCoord.lat == values.shape[0].lat &&
            newCoord.lng == values.shape[0].lng;
          if (firstPolygonCoordinateClicked) {
            const isClosedPolygon = values.shape.length > 2;
            if (isClosedPolygon) {
              await setFieldValue("isClosedPolygon", true);
              await setFieldValue(
                "size",
                getPolygonArea(values.shape, unitOfMeasurement),
              );
              await setFieldValue("sizeField", SIZE_FIELD.SIZE_AUTOMATIC);
            } else {
              // discard the new coordinate because the polygon has to have 3
              // coordinates before the first one can be clicked to close the polygon
              return;
            }
          } else {
            await setFieldValue("shape", [...values.shape, newCoord]);
            return;
          }
        }
      }
    },
    [
      setFieldValue,
      values.isClosedPolygon,
      values.position,
      values.shape,
      unitOfMeasurement,
    ],
  );

  const handleMapClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      const newCoord = e.latLng?.toJSON();

      if (!newCoord) {
        return;
      }

      switch (drawingMode) {
        case DRAWINGMODE.MARKER:
          handleMarkerSet(newCoord);
          break;
        case DRAWINGMODE.POLYGON:
          handlePolyVertexSet(newCoord);
          break;
      }
    },
    [drawingMode, handleMarkerSet, handlePolyVertexSet],
  );

  // Function for updating the line whilst still dragging
  const handlePolyVertexDrag = useCallback(
    (e: google.maps.MapMouseEvent, index: number) => {
      if (!values.shape) return;

      const newCoord = e.latLng?.toJSON();

      setFieldValue(
        "shape",
        values.shape.map((coord, polyIndex) =>
          polyIndex === index && newCoord ? newCoord : coord,
        ) || [],
      );
    },
    [setFieldValue, values.shape],
  );

  // Fct to handle end of the drag and give the new value into the formm /formik
  const handlePolyVertexDragEnd = useCallback(
    (e: google.maps.MapMouseEvent, index: number) => {
      if (!values.shape) return;

      // Save to state
      handlePolyVertexDrag(e, index);

      const newCoord = e.latLng?.toJSON();

      //Save to formik vaalues

      setFieldValue(
        "shape",
        values.shape.map((coord, polyIndex) =>
          polyIndex === index && newCoord ? newCoord : coord,
        ) || [],
      );
      setFieldValue("sizeField", SIZE_FIELD.SIZE_AUTOMATIC);
      setFieldValue("size", getPolygonArea(values.shape, unitOfMeasurement));
    },
    [handlePolyVertexDrag, setFieldValue, values.shape, unitOfMeasurement],
  );

  return {
    handleMapClick,
    handleMarkerDrag,
    handlePolyVertexDrag,
    handlePolyVertexDragEnd,
  };
};
