import {
  addParcels,
  createParcel,
  deleteParcels,
  getParcels,
  sendImportLink,
  updateParcel,
} from "Api";
import i18n from "i18n/i18n";
import { call, put, select, takeLatest, throttle } from "redux-saga/effects";
import { errorToast, successToast } from "Services";
import { AppState } from "Store/store";
import { sagaActions } from "./parcelsSagaActions";
import { parcelsSlice } from "./parcelsSlice";

function* fetchParcelsSaga(
  action: ReturnType<typeof sagaActions.fetchParcels>,
) {
  yield put(parcelsSlice.actions.setLoading(true));
  try {
    const params = { ...action.payload };
    const response = yield call(getParcels, params);
    yield put(
      parcelsSlice.actions.setList({
        list: response.data,
        maxCount: response.maxCount,
      }),
    );
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadParcels"), undefined, error);
  } finally {
    yield put(parcelsSlice.actions.setLoading(false));
  }
}

function* refreshParcels() {
  const { listFilters, listMeta } = yield select(
    (state: AppState) => state.parcels,
  );
  yield put(sagaActions.fetchParcels({ ...listFilters, ...listMeta }));
}

function* setListMetaSaga(action: ReturnType<typeof sagaActions.setListMeta>) {
  const filters = yield select((state: AppState) => state.parcels.listFilters);
  yield put(sagaActions.fetchParcels({ ...filters, ...action.payload }));
  yield put(parcelsSlice.actions.setListMeta(action.payload));
}

function* deleteParcelsSaga(
  action: ReturnType<typeof sagaActions.deleteParcels>,
) {
  yield put(parcelsSlice.actions.setLoading(true));
  try {
    yield call(deleteParcels, action.payload);

    yield refreshParcels();
  } catch (error) {
    errorToast(
      i18n.t("errors.unableToDeleteParcels", { count: action.payload.length }),
      undefined,
      error,
    );
  } finally {
    yield put(parcelsSlice.actions.setLoading(false));
  }
}

function* sendImportParcelLinkSaga(
  action: ReturnType<typeof sagaActions.sendImportLink>,
) {
  try {
    yield call(sendImportLink, action.payload);

    successToast(i18n.t("notification.linkSent"));
  } catch (error) {
    errorToast(i18n.t(""), undefined, error);
  }
}

function* importParcelsSaga(
  action: ReturnType<typeof sagaActions.importParcels>,
) {
  try {
    yield put(parcelsSlice.actions.setLoading(true));

    yield call(addParcels, action.payload);

    successToast(i18n.t("parcels.upload.importSuccess.title"));

    yield refreshParcels();
  } catch (error) {
    errorToast(i18n.t("notification.error.parcelimport"), undefined, error);
  } finally {
    yield put(parcelsSlice.actions.setLoading(false));
  }
}

function* updateParcelSaga(
  action: ReturnType<typeof sagaActions.updateParcel>,
) {
  try {
    yield put(parcelsSlice.actions.setUpdateLoading(true));

    const updatedParcel = yield call(
      updateParcel,
      action.payload.id,
      action.payload.update,
    );

    yield put(parcelsSlice.actions.setListItem(updatedParcel));
  } catch (error) {
    errorToast(i18n.t("errors.unableToSaveParcel"), undefined, error);
  } finally {
    yield put(parcelsSlice.actions.setUpdateLoading(false));
  }
}

function* createParcelSaga(
  action: ReturnType<typeof sagaActions.createParcel>,
) {
  try {
    yield put(parcelsSlice.actions.setUpdateLoading(true));

    yield call(createParcel, action.payload.parcel);

    yield refreshParcels();
  } catch (error) {
    errorToast(i18n.t("errors.unableToSaveParcel"), undefined, error);
  } finally {
    yield put(parcelsSlice.actions.setUpdateLoading(false));
  }
}

function* setFiltersSaga(action: ReturnType<typeof sagaActions.setFilters>) {
  const limit = yield select((state: AppState) => state.parcels.listMeta.limit);
  yield put(
    sagaActions.fetchParcels({
      ...action.payload,
      limit,
      offset: 0,
    }),
  );
  yield put(parcelsSlice.actions.setListMeta({ offset: 0 }));
  yield put(parcelsSlice.actions.setListFilters(action.payload));
}

export default function* parcelsSaga() {
  yield takeLatest(sagaActions.fetchParcels.type, fetchParcelsSaga);
  yield takeLatest(sagaActions.setListMeta.type, setListMetaSaga);
  yield takeLatest(sagaActions.deleteParcels.type, deleteParcelsSaga);
  yield takeLatest(sagaActions.sendImportLink.type, sendImportParcelLinkSaga);
  yield takeLatest(sagaActions.importParcels.type, importParcelsSaga);
  yield takeLatest(sagaActions.updateParcel.type, updateParcelSaga);
  yield takeLatest(sagaActions.createParcel.type, createParcelSaga);
  yield throttle(500, sagaActions.setFilters.type, setFiltersSaga);
}
