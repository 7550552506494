import { DateMode } from "@ero/app-common/enums/DateTimeMode";
import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { AddOrderRequestBody } from "@ero/app-common/v2/routes/models/order";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/order";
import { schema } from "@ero/app-common/v2/routes/schema/order";
import { getOrderNamePreset } from "@ero/app-common/v2/utils/prepopulateOrderName";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { getOrdersV2 } from "Api";
import { CloseButton } from "Components/closeButton/closeButton";
import { FullscreenButton } from "Components/fullScreenButton/FullscreenButton";
import { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getStartAndEndDay } from "Utils";
import { DialogActionButtons } from "./components/dialogActionButtons";
import { DialogContents } from "./components/dialogContents";
import { StepperContent } from "./components/stepperContent";
import { initialValues, useValidationSchema, ValuesType } from "./formConfig";
import { Steps } from "./steps";

const StyledDialog = styled(Dialog)(() => ({
  [".MuiDialog-paper"]: {
    height: "80vh",
    ["> .MuiPaper-root"]: {
      justifyContent: "space-between",
    },
  },
  [".MuiDialog-paperFullScreen"]: {
    height: "100vh",
  },
}));

interface CreateOrderDialog {
  open: boolean;
  createAction: ActionCreatorWithPayload<AddOrderRequestBody>;
  onClose: (orderDateRange?: { start: Dayjs; end: Dayjs }) => void;
  defaultDate?: number;
}

const createSchema = schema[baseUrl][SubUrls.ROOT][HTTP_METHOD.POST]["body"];

export const CreateOrderDialog: React.FC<CreateOrderDialog> = ({
  open,
  createAction,
  onClose,
  defaultDate,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const [activeStep, setActiveStep] = useState(Steps.GENERAL);
  const [stepsExtended, setStepsExtended] = useState(false);

  const [selectedParcels, setSelectedParcels] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [allParcelsOfCustomer, setAllParcelsOfCustomer] = useState<
    ParcelsResponseBody["data"]
  >([]);

  const [lastOrderId, setLastOrderId] = useState<number | undefined>();

  const closeCreateModal = useCallback(
    (newOrder?: AddOrderRequestBody) => {
      setActiveStep(0);

      if ((newOrder?.jobs?.length ?? 0) == 0) {
        onClose();
        return;
      }

      onClose(getStartAndEndDay(newOrder?.dateRestrictions));
    },
    [onClose],
  );

  const validationSchema = useValidationSchema(stepsExtended);

  const preparedInitialValues: ValuesType = useMemo(
    () => ({
      ...initialValues,
      name:
        initialValues.name ||
        (lastOrderId !== undefined ? getOrderNamePreset(lastOrderId) : ""),
      dateRestrictions: defaultDate
        ? { ...initialValues.dateRestrictions, date: defaultDate }
        : initialValues.dateRestrictions,
    }),
    [lastOrderId, defaultDate],
  );

  const handleFormSubmit = useCallback(
    (values: ValuesType) => {
      const { dateRestrictions } = values;
      let dateMode = dateRestrictions.dateMode;
      let timeMode = dateRestrictions.timeMode;

      if (
        dateRestrictions.dateMode === DateMode.FIXEDDATE &&
        !dateRestrictions.date
      ) {
        dateMode = undefined;
        timeMode = undefined;
      }

      const newOrder = createSchema.cast(
        {
          name: values.name,
          customer: +values.customer,
          notes: values.notes,
          dateRestrictions: {
            ...dateRestrictions,
            dateMode,
            timeMode,
            dayTime: dateRestrictions.dayTime ?? {},
          },
          jobs: values.jobs
            .filter((entry) => entry !== undefined && entry.create)
            .map((job) => {
              if (job?.parcel && job.service)
                return {
                  parcel: job?.parcel,
                  service: job?.service,
                };
            }) as AddOrderRequestBody["jobs"],
        },
        {
          stripUnknown: true,
          // ignore invalid data
          assert: false,
        },
      );

      dispatch(createAction(newOrder));
      closeCreateModal(newOrder);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeCreateModal],
  );

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen((curr) => !curr);
  }, []);

  useEffect(() => {
    const fetchLastOrderId = async () => {
      const lastOrderId = (await getOrdersV2({ limit: 1 })).lastOrderId;
      setLastOrderId(lastOrderId);
    };
    fetchLastOrderId();
  }, []);

  return (
    <>
      <StyledDialog
        maxWidth="lg"
        open={open}
        fullScreen={isFullscreen}
        fullWidth
        scroll="body"
      >
        <DialogTitle>{t("orders.create")}</DialogTitle>
        <FullscreenButton
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
        />
        <CloseButton onClose={closeCreateModal} />
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={preparedInitialValues}
          onSubmit={handleFormSubmit}
        >
          {() => (
            <Form
              style={{
                height: "calc(100% - 64px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DialogContent
                dividers
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                }}
              >
                <Box p={1}>
                  <StepperContent
                    activeStep={activeStep}
                    stepsExtended={stepsExtended}
                  />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 56px)",
                  }}
                >
                  <DialogContents
                    activeStep={activeStep}
                    selectedParcels={selectedParcels}
                    setSelectedParcels={setSelectedParcels}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    isFullScreen={isFullscreen}
                    allParcelsOfCustomer={allParcelsOfCustomer}
                    setAllParcelsOfCustomer={setAllParcelsOfCustomer}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <DialogActionButtons
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  stepsExtended={stepsExtended}
                  setStepsExtended={setStepsExtended}
                  selectedParcels={selectedParcels}
                  selectedServices={selectedServices}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </StyledDialog>
    </>
  );
};
