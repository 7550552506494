import { Add } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { ConfirmDialog } from "Components";
import { ROUTES } from "Constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppState } from "Store";
import { getCustomersInitial } from "Store/customers";
import { ImportParcelsModal } from "./components/importParcelsModal/importParcelsModal";
import { SendImportLinkModal } from "./components/sendImportLinkModal/sendImportLinkModal";

export const AddParcelButton: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { maxCount: customersCount, listMeta } = useSelector(
    (state: AppState) => state.customers,
  );

  const [open, setOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isImportLinkModalOpen, setIsImportLinkModalOpen] = useState(false);
  const [isCreateCustomersModalOpen, setIsCreateCustomersModalOpen] =
    useState(false);
  const [createCustomersModalText, setCreateCustomersModalText] = useState("");

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const close = useCallback((event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }, []);

  const closeImportModal = useCallback(() => {
    setIsImportModalOpen(false);
  }, []);

  const closeImportLinkModal = useCallback(() => {
    setIsImportLinkModalOpen(false);
  }, []);

  const onCreateClick = useCallback(() => {
    navigate("", { state: { create: true } });
  }, [navigate]);

  const onImportClick = useCallback(() => {
    if (customersCount === 0) {
      setCreateCustomersModalText(t("parcels.noCustomersAvailableModal.text"));
      setIsCreateCustomersModalOpen(true);
    } else {
      setIsImportModalOpen(true);
    }
  }, [customersCount, t]);

  const onSendImportLinkClick = useCallback(() => {
    if (customersCount === 0) {
      setCreateCustomersModalText(
        t("parcels.noCustomersAvailableModal.textSendLink"),
      );
      setIsCreateCustomersModalOpen(true);
    } else {
      setIsImportLinkModalOpen(true);
    }
  }, [customersCount, t]);

  const closeConfirmDialog = useCallback(
    () => setIsCreateCustomersModalOpen(false),
    [],
  );

  const approveConfirmDialog = useCallback(() => {
    navigate(`${ROUTES.MAIN.CUSTOMERS}`, {
      state: { create: true },
    });
  }, [navigate]);

  useEffect(() => {
    if (customersCount === 0) {
      dispatch(getCustomersInitial(listMeta));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersCount, listMeta]);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="contained"
        startIcon={<Add />}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleToggle}
      >
        {t("general.buttons.add")}
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={close}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={onCreateClick}>
                    {t("parcels.create")}
                  </MenuItem>
                  <MenuItem onClick={onImportClick}>
                    {t("parcels.createMultiple")}
                  </MenuItem>
                  <MenuItem onClick={onSendImportLinkClick}>
                    {t("parcels.sendImportLink")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <SendImportLinkModal
        isOpen={isImportLinkModalOpen}
        onClose={closeImportLinkModal}
      />
      <ImportParcelsModal
        isOpen={isImportModalOpen}
        onClose={closeImportModal}
      />
      <ConfirmDialog
        isOpen={isCreateCustomersModalOpen}
        onApprove={approveConfirmDialog}
        onClose={closeConfirmDialog}
        title={t("parcels.noCustomersAvailableModal.title")}
        text={createCustomersModalText}
        text_2={t("parcels.noCustomersAvailableModal.text2")}
      />
    </>
  );
};
