import { Email } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Input } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { VALIDATION_ERRORS } from "Constants";
import { sagaActions } from "Store/parcels/parcelsSagaActions";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { CustomerInput } from "./components/customerInput";

type SendImportLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type ValuesType = {
  customer: string | number;
  email: string;
};

const initialValues = {
  customer: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  customer: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
  email: Yup.string()
    .email(VALIDATION_ERRORS.VALID_EMAIL)
    .required(VALIDATION_ERRORS.REQUIRED),
});

export const SendImportLinkModal: React.FC<SendImportLinkModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback(
    (values: ValuesType) => {
      dispatch(
        sagaActions.sendImportLink({
          customer: +values.customer,
          email: values.email,
        }),
      );
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle>{t("parcels.upload.importLink.title")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {({ isValid, dirty, submitForm }) => (
          <>
            <DialogContent dividers>
              <Form>
                <CustomerInput />
                <Input
                  name="email"
                  label={t("general.fields.email")}
                  StartIcon={<Email />}
                  sx={{ mt: 1, mb: 1 }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={!(isValid && dirty)}
                startIcon={<Email />}
              >
                {t("parcels.upload.importLink.submit")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
