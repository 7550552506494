import { Grid2, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";

type OrderNameProps = {
  orderName: string;
  showAllInfo: boolean;
};

export const OrderName: React.FC<OrderNameProps> = ({
  orderName,
  showAllInfo,
}) => {
  const isLongOrderName = useMemo(
    () => orderName && orderName.length > 50,
    [orderName],
  );

  const ellipsisStyles = useMemo(
    () =>
      showAllInfo
        ? {}
        : {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
    [showAllInfo],
  );

  return (
    <Grid2 size={11}>
      <Tooltip title={isLongOrderName && !showAllInfo ? orderName : ""}>
        <Typography
          variant="body1"
          sx={{
            ...ellipsisStyles,
            fontWeight: 500,
          }}
        >
          {orderName}
        </Typography>
      </Tooltip>
    </Grid2>
  );
};
