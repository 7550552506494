import {
  GridApi,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ImportParcelTableRow } from "Components/importOverview/parcelsOverviewModal";
import { SelectionTable } from "Components/tableV2/selectionTable/SelectionTable";
import { OptionType } from "Types";
import React, { useMemo } from "react";
import { useColumns } from "./tableConfig";

interface IParcelsTable {
  apiRef?: React.MutableRefObject<GridApi>;
  parcelData: ImportParcelTableRow[];
  selectedParcelIds: number[];
  rowModesModel: GridRowModesModel;
  onRowClick: (params: GridRowParams<ImportParcelTableRow>) => void;
  onRowSelect: (ids: readonly GridRowId[]) => void;
  handleUpdateRow: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel,
  ) => GridValidRowModel;
  handleUpdateRowError: (error: any) => void;
  grapeVarietyOptions: OptionType[];
  editRowId: GridRowId | undefined;
  actions: {
    onEditClick: (id: GridRowId) => void;
    onSaveClick: (id: GridRowId) => void;
    onCancelClick: (id: GridRowId) => void;
  };
  disableActionButtons: boolean;
}

export const ParcelsTable: React.FC<IParcelsTable> = ({
  apiRef,
  parcelData,
  selectedParcelIds,
  rowModesModel,
  onRowClick,
  onRowSelect,
  handleUpdateRow,
  handleUpdateRowError,
  grapeVarietyOptions,
  editRowId,
  actions: { onEditClick, onSaveClick, onCancelClick },
  disableActionButtons,
}) => {
  const columns = useColumns(
    grapeVarietyOptions,
    editRowId,
    onEditClick,
    onSaveClick,
    onCancelClick,
    disableActionButtons,
  );

  const hideSearchBox = useMemo(() => editRowId !== undefined, [editRowId]);

  const rows = useMemo(() => {
    const rows = parcelData.map((parcel) => {
      return {
        ...parcel,
        disabled: editRowId !== undefined && +editRowId !== parcel._id,
      };
    });
    return rows;
  }, [editRowId, parcelData]);

  return (
    <SelectionTable
      apiRef={apiRef}
      columns={columns}
      onRowSelectionModelChange={onRowSelect}
      rowSelectionModel={selectedParcelIds}
      disableRowSelectionOnClick
      rowModesModel={rowModesModel}
      processRowUpdate={handleUpdateRow}
      onProcessRowUpdateError={handleUpdateRowError}
      toolbarProps={{ hideSearchBox }}
      clientSidePagination
      clientSideFiltering
      rows={rows}
      onRowClick={onRowClick}
      loading={false}
    />
  );
};
