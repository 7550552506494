import { Add } from "@mui/icons-material";
import { Fab, Zoom } from "@mui/material";
import { ConfirmDialog } from "Components";
import { CreateOrderDialog } from "Components/createOrderDialog/createOrderDialog";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";

type AddOrderButtonProps = {
  isCreateOrderModalOpen: boolean;
  showCreateOrderModal: () => void;
  closeCreateOrderModal: (orderIdToHighlight?: number) => void;
};

export const AddOrderButton: React.FC<AddOrderButtonProps> = ({
  isCreateOrderModalOpen,
  showCreateOrderModal,
  closeCreateOrderModal,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [newOrderStartDate, setNewOrderStartDate] = useState<
    number | undefined
  >(undefined);

  const { orders, date, loadingOrders } = useSelector(
    (state: AppState) => state.planningV2,
  );

  const onOrderModalClose = useCallback(
    (orderDateRange?: { start: Dayjs; end: Dayjs }) => {
      closeCreateOrderModal();
      const calendarDate = dayjs(date);
      if (
        orderDateRange &&
        (calendarDate.isBefore(orderDateRange.start) ||
          calendarDate.isAfter(orderDateRange.end))
      ) {
        setNewOrderStartDate(orderDateRange.start.unix() * 1000);
      }
    },
    [closeCreateOrderModal, date],
  );

  const onConfirmModalClose = useCallback(() => {
    setNewOrderStartDate(undefined);
  }, []);

  const onConfirmModalApprove = useCallback(() => {
    if (newOrderStartDate) dispatch(sagaActions.setDate(newOrderStartDate));
    setNewOrderStartDate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrderStartDate]);
  return (
    <>
      <Zoom in={!loadingOrders && orders.length > 0}>
        <Fab
          style={{
            transitionDelay: "500ms",
            position: "absolute",
            bottom: 16,
            right: 30,
          }}
          color="primary"
          onClick={showCreateOrderModal}
        >
          <Add />
        </Fab>
      </Zoom>
      {isCreateOrderModalOpen && (
        <CreateOrderDialog
          open={isCreateOrderModalOpen}
          createAction={sagaActions.createOrder}
          onClose={onOrderModalClose}
          defaultDate={date}
        />
      )}
      <ConfirmDialog
        isOpen={newOrderStartDate !== undefined}
        onClose={onConfirmModalClose}
        onApprove={onConfirmModalApprove}
        title={t("orders.created")}
        text={t("planningV2.createOrder.confirmationModal.text")}
      />
    </>
  );
};
