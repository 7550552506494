import { Autocomplete, TextField, TextFieldVariants } from "@mui/material";
import { OptionType } from "Types/internal";
import { useField } from "formik";
import React, { ComponentProps, useCallback, useMemo } from "react";

type Props = Omit<
  ComponentProps<typeof Autocomplete<OptionType, false, boolean>>,
  | "multiple"
  | "renderInput"
  | "getOptionDisabled"
  | "options"
  | "renderOption"
  | "onChange"
  | "size"
  | "slotProps"
> & {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  size?: "medium" | "small";
  disabledValues?: (number | string)[];
  options: OptionType[];
  onChange?: (value: OptionType | null) => void;
  valueSource?: "value" | "label";
  nullable?: boolean;
  container?: Element | null;
  variant?: TextFieldVariants;
};

const StaticSelectWithAutoCompleteComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (props, ref) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    disabledValues,
    options,
    loading = false,
    disableClearable = false,
    sx,
    placeholder,
    onChange: propsOnChange,
    valueSource = "value",
    size = "medium",
    nullable = false,
    container,
    variant,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);

  const autocompleteValue: OptionType | null = useMemo(() => {
    const selectedOption = options.find(
      (option) => option[valueSource] === field.value,
    );
    return selectedOption ?? null;
  }, [field.value, options, valueSource]);

  const optionsToDisable = (option: OptionType) => {
    return !!disabledValues?.includes(option.value);
  };

  const renderOption = useCallback(
    (props, option: OptionType) => (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    ),
    [],
  );

  const onChange = useCallback(
    (_: React.SyntheticEvent, value: OptionType | null) => {
      if (value) {
        helpers.setValue(value[valueSource]);
      } else {
        if (nullable) helpers.setValue(undefined);
        else helpers.setValue(valueSource === "value" ? meta.initialValue : "");
      }
      if (propsOnChange) propsOnChange(value);
    },
    [propsOnChange, helpers, valueSource, nullable, meta.initialValue],
  );

  return (
    <Autocomplete
      {...rest}
      {...field}
      ref={ref}
      sx={sx}
      disableClearable={disableClearable}
      value={autocompleteValue}
      disabled={disabled}
      multiple={false}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder || params.inputProps.placeholder}
          inputProps={{ ...params.inputProps, placeholder }}
          helperText={meta.error}
          error={!!meta.error}
          variant={variant}
        />
      )}
      getOptionDisabled={optionsToDisable}
      options={options}
      renderOption={renderOption}
      onChange={onChange}
      size={size}
      slotProps={{
        popper: {
          container,
        },
      }}
    />
  );
};

export const StaticSelectWithAutoComplete = React.forwardRef(
  StaticSelectWithAutoCompleteComponent,
);
StaticSelectWithAutoComplete.displayName = "StaticSelectWithAutoComplete";
