import { DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { OrderDateType } from "@ero/app-common/models";
import { BusinessHoursInput } from "@fullcalendar/core";
import dayjs from "dayjs";
import { getStartAndEndDay } from "Utils";

const startOfDay = "00:00";
const endOfDay = "24:00";

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

const getOffsetFromDayStart = (date: number | undefined) => {
  const dayjsDate = dayjs(date);
  return dayjsDate.diff(dayjsDate.startOf("day"));
};

const getSlot = (
  date: number,
  orderDateRestrictions: OrderDateType | undefined,
) => {
  const currentDate = dayjs(date).startOf("day");
  const startAndEndDay = getStartAndEndDay(orderDateRestrictions);
  if (
    !startAndEndDay ||
    currentDate.isBefore(startAndEndDay.start) ||
    currentDate.isAfter(startAndEndDay.end)
  ) {
    return undefined;
  }

  switch (orderDateRestrictions?.timeMode) {
    case DateTimeMode.WITHOUTTIME:
      return startAndEndDay;
    case DateTimeMode.WITHDAYTIME:
      return {
        start: dayjs(
          currentDate.add(
            getOffsetFromDayStart(orderDateRestrictions.dayTime?.startTime),
          ),
        ),
        end: dayjs(
          currentDate.add(
            getOffsetFromDayStart(orderDateRestrictions.dayTime?.endTime),
          ),
        ),
      };
    case DateTimeMode.WITHTIME:
      return {
        start: currentDate.isSame(startAndEndDay.start, "day")
          ? dayjs(
              currentDate.add(
                getOffsetFromDayStart(
                  orderDateRestrictions.startDate ?? orderDateRestrictions.date,
                ),
              ),
            )
          : currentDate,
        end:
          orderDateRestrictions.endDate &&
          currentDate.isSame(startAndEndDay.end, "day")
            ? dayjs(
                currentDate.add(
                  getOffsetFromDayStart(orderDateRestrictions.endDate),
                ),
              )
            : currentDate.endOf("day"),
      };
    default:
      return undefined;
  }
};

export const violatesDateRestrictions = (
  calendarDate: number,
  orderDateRestrictions: OrderDateType | undefined,
  eventStart?: Date | null,
  eventEnd?: Date | null,
): boolean | undefined => {
  if (!eventStart && !eventEnd) {
    return false;
  }

  const slot = getSlot(calendarDate, orderDateRestrictions);

  if (!slot) {
    return false;
  }

  const dayjsEventStart = dayjs(eventStart);
  const dayjsEventEnd = dayjs(eventEnd);

  return (
    dayjsEventStart.isBefore(slot.start) || dayjsEventEnd.isAfter(slot.end)
  );
};

export const getBusinessHours = (
  calendarDate: number,
  orderDateRestrictions: OrderDateType | undefined,
): BusinessHoursInput => {
  const slot = getSlot(calendarDate, orderDateRestrictions);

  if (!slot) {
    return { startTime: startOfDay, endTime: endOfDay, daysOfWeek };
  }

  return {
    startTime: slot.start.format("HH:mm"),
    endTime: slot.end.format("HH:mm"),
    daysOfWeek,
  };
};
