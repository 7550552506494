import { Grid2, Paper } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid";
import { ParcelModal } from "Components";
import { FullscreenContext } from "Contexts/fullScreenContext";
import { type AppState } from "Store";
import { sagaActions } from "Store/parcels/parcelsSagaActions";
import { parcelsSlice } from "Store/parcels/parcelsSlice";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ParcelsMap } from "./components/ParcelsMap";
import { ParcelsTable } from "./components/parcelsTable/ParcelsTable";

export const Parcels: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state: routerState } = useLocation();

  const tableApiRef = useGridApiRef();

  const { list } = useSelector((state: AppState) => state.parcels);

  const { modalParcel, isParcelModalOpen } = useMemo(() => {
    if (routerState?.create) {
      return { modalParcel: null, isParcelModalOpen: true };
    }
    const modalParcel = list.find((p) => p._id === routerState?.id);
    return { modalParcel, isParcelModalOpen: modalParcel !== undefined };
  }, [list, routerState?.create, routerState?.id]);

  const onItemClick = useCallback(
    (id: number) => {
      tableApiRef.current.scrollToIndexes({
        rowIndex: list.findIndex((p) => p._id === +id),
      });
      navigate("", { state: { id: +id } });
    },
    [list, navigate, tableApiRef],
  );

  const closeParcelModal = useCallback(() => {
    navigate("", { state: { id: undefined } });
  }, [navigate]);

  useEffect(() => {
    if (!routerState?.id) {
      dispatch(parcelsSlice.actions.setListMeta({ offset: 0 }));
    }
    if (routerState?.customer) {
      dispatch(sagaActions.setFilters({ customer: routerState.customer }));
    } else {
      dispatch(sagaActions.setFilters({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid2 container height="100%">
        <Grid2 size={7} height="100%">
          <ParcelsTable onRowClick={onItemClick} apiRef={tableApiRef} />
        </Grid2>
        <Grid2 size={5} sx={{ height: "100%", p: 1, pl: 1 }}>
          <Paper sx={{ height: "100%" }} elevation={5}>
            <FullscreenContext sx={{ height: "100%" }}>
              <ParcelModal
                isOpen={isParcelModalOpen}
                close={closeParcelModal}
                parcel={modalParcel}
              />
              <ParcelsMap onParcelClick={onItemClick} />
            </FullscreenContext>
          </Paper>
        </Grid2>
      </Grid2>
    </>
  );
};
