import { Flag, Lock } from "@mui/icons-material";
import { Grid2 } from "@mui/material";
import React from "react";
import { OrderDateRestrictionsWarning } from "./orderDateRestrictionsWarning";

type FlagsProps = {
  eventViolatesOrderDateRestrictions: boolean;
  eventIsMoving: boolean;
  eventLocked: boolean;
  showAllInfo: boolean;
  selected: boolean;
};

export const Flags: React.FC<FlagsProps> = ({
  eventViolatesOrderDateRestrictions,
  eventIsMoving,
  eventLocked,
  showAllInfo,
  selected,
}) => {
  if (!(selected || eventLocked || eventViolatesOrderDateRestrictions)) {
    return <></>;
  }

  return (
    <Grid2>
      <OrderDateRestrictionsWarning
        eventViolatesOrderDateRestrictions={eventViolatesOrderDateRestrictions}
        eventIsMoving={eventIsMoving}
        showAllInfo={showAllInfo}
      />
      {selected && <Flag fontSize={"small"} htmlColor={"yellow"} />}
      {eventLocked && <Lock fontSize={"small"} />}
    </Grid2>
  );
};
