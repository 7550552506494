import { BusinessHoursInput } from "@fullcalendar/core";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

type ContextType = {
  activeEventOrderId: number | undefined;
  setActive: (
    eventOrderId: number,
    businessHours: BusinessHoursInput | undefined,
  ) => void;
  setInactive: () => void;
  businessHours: BusinessHoursInput | undefined;
};

const Context = createContext<ContextType>({
  activeEventOrderId: undefined,
  setActive: () => {},
  setInactive: () => {},
  businessHours: undefined,
});

export const useEventDraggingContext = () => useContext(Context);

export const EventDraggingContext: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [activeEventOrderId, setActiveEventOrderId] = useState<
    number | undefined
  >();
  const [businessHours, setBusinessHours] = useState<
    BusinessHoursInput | undefined
  >();

  const handleSetActive = useCallback(
    (eventOrderId: number, businessHours: BusinessHoursInput | undefined) => {
      setActiveEventOrderId(eventOrderId);
      setBusinessHours(businessHours);
    },
    [],
  );

  const handleSetInactive = useCallback(() => {
    setBusinessHours(undefined);
    setActiveEventOrderId(undefined);
  }, []);

  return (
    <Context.Provider
      value={{
        setActive: handleSetActive,
        setInactive: handleSetInactive,
        activeEventOrderId,
        businessHours,
      }}
    >
      {children}
    </Context.Provider>
  );
};
