import { styled } from "@mui/material";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";

export const TableFilterSelectWithAutoComplete = styled(
  SelectWithAutoComplete,
  {
    name: "MuiDataGrid",
    slot: "ToolbarQuickFilter",
  },
)(({ theme }) => ({
  width: 200,
  paddingTop: "3px",
  paddingBottom: theme.spacing(0.5),
  "& input": {
    marginLeft: theme.spacing(0.5),
  },
  "& .MuiInput-root": {
    marginTop: 0,
  },
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const TableFilterStaticSelectWithAutoComplete = styled(
  StaticSelectWithAutoComplete,
  {
    name: "MuiDataGrid",
    slot: "ToolbarQuickFilter",
  },
)(({ theme }) => ({
  width: 200,
  paddingTop: "3px",
  paddingBottom: theme.spacing(0.5),
  "& input": {
    marginLeft: theme.spacing(0.5),
  },
  "& .MuiInput-root": {
    marginTop: 0,
  },
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
