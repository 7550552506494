import {
  AddOrderRequestBody,
  OrdersRequestQuery,
} from "@ero/app-common/v2/routes/models/order";
import { createAction } from "@reduxjs/toolkit";

const fetchOrders = createAction<OrdersRequestQuery | undefined>(
  "ordersSaga/fetch-orders",
);

const createOrder = createAction<AddOrderRequestBody>(
  "ordersSaga/create-order",
);

const setListMetaAndFilters = createAction<{
  listMeta: { limit?: number; offset: number };
  listFilters: Omit<OrdersRequestQuery, "limit" | "offset" | "slim">;
}>("ordersSaga/set-listmetaandfilters");

const deleteOrders = createAction<number[]>("ordersSaga/delete-orders");

export const sagaActions = {
  fetchOrders,
  createOrder,
  deleteOrders,
  setListMetaAndFilters,
};
