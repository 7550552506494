import { styled } from "@mui/material";
import { DateInput } from "Components/FormikDateInput/DateInput";
import React, { ComponentProps } from "react";

const StandardDateInput: React.FC<ComponentProps<typeof DateInput>> = (
  props,
) => {
  return (
    <DateInput
      {...props}
      slotProps={{
        ...props.slotProps,
        textField: { ...props.slotProps?.textField, variant: "standard" },
      }}
    />
  );
};

export const TableFilterDateInput = styled(StandardDateInput, {
  name: "MuiDataGrid",
  slot: "ToolbarQuickFilter",
})(({ theme }) => ({
  width: 200,
  paddingBottom: theme.spacing(0.5),
  "& input": {
    marginLeft: theme.spacing(0.5),
  },
  "& .MuiInput-root": {
    marginTop: 0,
  },
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
