import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Dialog, DialogTitle } from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import ParcelsOverview, {
  ImportParcelTableRow,
} from "Components/importOverview/parcelsOverviewModal";
import { ParcelImportForm } from "Components/parcelImport/parcelImportForm";
import { sagaActions } from "Store/parcels/parcelsSagaActions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type ImportParcelsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ImportParcelsModal: React.FC<ImportParcelsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const [showOverview, setShowOverview] = useState(false);

  const [importData, setImportData] = useState<
    ImportResponseBody | undefined
  >();

  const onImportSuccess = (responseData?: ImportResponseBody) => {
    setImportData(responseData);
    setShowOverview(true);
  };

  const handleOverviewClose = () => {
    setShowOverview(false);
    onClose();
  };

  const handleAddSelectedParcels = async (
    importID: number,
    parcels: ImportParcelTableRow[],
  ) => {
    if (importData?._id) {
      dispatch(
        sagaActions.importParcels({
          importID,
          parcels: parcels.map((p) => ({
            ...p,
            flurstuecksnummern: p.flurstuecksnummern ?? [],
          })),
        }),
      );
    }
    handleOverviewClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("parcels.createMultiple")}</DialogTitle>
        <CloseButton onClose={onClose} />
        <ParcelImportForm onSuccess={onImportSuccess} />
      </Dialog>
      {showOverview && importData && (
        <ParcelsOverview
          isOpen={showOverview}
          onClose={handleOverviewClose}
          onAddSelectedParcels={handleAddSelectedParcels}
          importData={importData}
        />
      )}
    </>
  );
};
