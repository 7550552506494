import React, { PropsWithChildren } from "react";

type TabPanelProps = {
  index: number;
  value: number;
};

export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
