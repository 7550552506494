import * as Yup from "yup";

import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { Coordinate } from "@ero/app-common/util/Coordinate";
import {
  AddParcelRequestBody,
  UpdateParcelRequestBody,
} from "@ero/app-common/v2/routes/models/parcel";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/parcel";
import { schema } from "@ero/app-common/v2/routes/schema/parcel";
import { VALIDATION_ERRORS } from "Constants";
import { useMemo } from "react";

// helper type for re-declaring properties to 'X | ""' because of html input fields returning empty fields as empty string (even for type=number)
type InputCompatible<T, K extends keyof T> = Pick<
  {
    [key in keyof T]: T[key] | "";
  },
  K
> &
  Omit<T, K>;

export type FormikValues = Pick<
  InputCompatible<
    UpdateParcelRequestBody & AddParcelRequestBody,
    "customer" | "grapeVariety" | "crop" | "size" | "sizeField"
  >,
  | "name"
  | "customer"
  | "grapeVariety"
  | "size"
  | "sizeField"
  | "mark"
  | "regionette"
  | "position"
  | "shape"
  | "notes"
> & { isClosedPolygon: boolean; parcelId: number | undefined };

const updateSchema = schema[baseUrl][SubUrls.BY_ID][HTTP_METHOD.PATCH]["body"];

// validation schema has to be retrieved via a function as VALIDATION_ERRORS is filled with translated errors on loading/language change
export const useValidationSchema = () =>
  useMemo(
    () =>
      updateSchema.concat(
        Yup.object().shape({
          // redeclare some props because of error messages
          name: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
          customer: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
          // additional prop for map handling
          isClosedPolygon: Yup.boolean().when("shape", {
            is: (val: Coordinate[] | null | undefined) =>
              val === undefined || val === null || val.length === 0,
            then: (schema) => schema,
            otherwise: (schema) => schema.oneOf([true]),
          }),
        }),
      ),
    [],
  );
