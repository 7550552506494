import { createOrder, deleteOrders, getOrdersV2 } from "Api";
import i18n from "i18n/i18n";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { errorToast } from "Services";
import { planningV2Slice } from "Store/planningV2/planningV2slice";
import { AppState } from "Store/store";
import { sagaActions } from "./ordersSagaActions";
import { ordersSlice } from "./ordersSlice";

function* fetchOrdersSaga(action: ReturnType<typeof sagaActions.fetchOrders>) {
  yield put(ordersSlice.actions.setLoading(true));
  try {
    const params = { ...action.payload };
    const response = yield call(getOrdersV2, params);
    yield put(
      ordersSlice.actions.setList({
        list: response.data,
        maxCount: response.maxCount,
        lastOrderId: response.lastOrderId,
      }),
    );
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadOrders"), undefined, error);
  } finally {
    yield put(ordersSlice.actions.setLoading(false));
  }
}

function* refreshOrders() {
  const { listFilters, listMeta } = yield select(
    (state: AppState) => state.orders,
  );
  yield put(sagaActions.fetchOrders({ ...listFilters, ...listMeta }));
}

function* deleteOrdersSaga(
  action: ReturnType<typeof sagaActions.deleteOrders>,
) {
  yield put(ordersSlice.actions.setLoading(true));
  try {
    yield call(deleteOrders, { id: action.payload.map((id) => id.toString()) });

    yield refreshOrders();
  } catch (error) {
    errorToast(
      i18n.t("errors.unableToDeleteOrders", { count: action.payload.length }),
      undefined,
      error,
    );
  } finally {
    yield put(ordersSlice.actions.setLoading(false));
  }
}

function* createOrderSaga(action: ReturnType<typeof sagaActions.createOrder>) {
  try {
    yield put(ordersSlice.actions.setUpdateLoading(true));

    const createdOrder = yield call(createOrder, action.payload);

    yield put(planningV2Slice.actions.setRecentlyAddedOrder(createdOrder._id));

    yield refreshOrders();
  } catch (error) {
    errorToast(i18n.t("errors.unableToSaveOrder"), undefined, error);
  } finally {
    yield put(ordersSlice.actions.setUpdateLoading(false));
  }
}

function* setListMetaAndFiltersSaga(
  action: ReturnType<typeof sagaActions.setListMetaAndFilters>,
) {
  yield put(
    sagaActions.fetchOrders({
      ...action.payload.listFilters,
      ...action.payload.listMeta,
    }),
  );
  yield put(ordersSlice.actions.setListMeta(action.payload.listMeta));
  yield put(ordersSlice.actions.setListFilters(action.payload.listFilters));
}

export default function* ordersSaga() {
  yield takeLatest(sagaActions.fetchOrders.type, fetchOrdersSaga);
  yield takeLatest(sagaActions.deleteOrders.type, deleteOrdersSaga);
  yield takeLatest(sagaActions.createOrder.type, createOrderSaga);
  yield takeLatest(
    sagaActions.setListMetaAndFilters.type,
    setListMetaAndFiltersSaga,
  );
}
