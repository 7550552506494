import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { numberFormatterWithDefault } from "@ero/app-common/util/numberFormatter";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { AppState } from "Store";
import { displaySizeOrFallback as displaySize } from "Utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("general.fields.name"),
      },
      {
        field: "customer",
        headerName: t("general.fields.customer"),
        valueGetter: (value) => value.companyName,
      },
      {
        field: "grapeVariety",
        headerName: t("parcels.fields.grapeVariety"),
        valueGetter: (value) => ((value?._id ?? -1) !== -1 ? value?.name : ""),
      },
      {
        field: "size",
        headerName: t("parcels.fields.sizeUnit", {
          unit: UnitSymbol[unitOfMeasurement],
        }),
        valueGetter: (value) =>
          displaySize(value, unitOfMeasurement, "", false),
      },
      {
        field: "rowAmount",
        headerName: t("parcels.fields.rowAmount"),
        valueGetter: (value) => numberFormatterWithDefault(value, ""),
      },
      {
        field: "mark",
        headerName: t("parcels.fields.mark"),
      },
      {
        field: "regionette",
        headerName: t("parcels.fields.regionette"),
      },
    ],
    [unitOfMeasurement, t],
  );
  return columns;
};

export const defaultHiddenColumns = {
  plantAmount: false,
  plantGap: false,
  rowAmount: false,
  rowGap: false,
  separatedregion: false,
  largeRegion: false,
  growingarea: false,
  clone: false,
  base: false,
  slope: false,
  trainingType: false,
  color: false,
  stain: false,
  plantingDate: false,
  slopeDirection: false,
  notes: false,
};

export const defaultHiddenColumnsCreateOrder = {
  customer: false,
  plantAmount: false,
  plantGap: false,
  rowGap: false,
  separatedregion: false,
  largeRegion: false,
  growingarea: false,
  clone: false,
  base: false,
  slope: false,
  trainingType: false,
  color: false,
  stain: false,
  plantingDate: false,
  slopeDirection: false,
  notes: false,
  mark: false,
  flurstuecke: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "string", access: ["name"] },
  { type: "number", access: ["rowAmount"], default: "-" },
  { type: "number", access: ["rowGap"], default: "-" },
  { type: "number", access: ["planAmount"], default: "-" },
  { type: "number", access: ["plantGap"], default: "-" },
  { type: "number", access: ["size"], default: "-" },
  { type: "string", access: ["separatedregion"] },
  { type: "string", access: ["growingarea"] },
  { type: "string", access: ["largeregion"] },
  { type: "string", access: ["regionette"] },
  { type: "string", access: ["mark"] },
  { type: "string", access: ["clone"] },
  { type: "string", access: ["base"] },
  { type: "string", access: ["notes"] },
];
