import {
  ParcelResponseBody,
  ParcelsRequestQuery,
} from "@ero/app-common/v2/routes/models/parcel";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ParcelsState = {
  list: ParcelResponseBody[];
  listMeta: {
    offset: number;
    limit: number;
    maxCount: number;
  };
  listLoading: boolean;
  listFilters: ParcelsRequestQuery;
  updateLoading: boolean;
  updateDone: boolean;
  columnVisibilityModel: GridColumnVisibilityModel;
  zoom: number;
};

const initialState: ParcelsState = {
  list: [],
  listMeta: {
    offset: 0,
    limit: 25,
    maxCount: 9999,
  },
  listLoading: false,
  listFilters: {},
  updateLoading: false,
  updateDone: false,
  columnVisibilityModel: {},
  zoom: 16,
};

export const parcelsSlice = createSlice({
  name: "parcels",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.listLoading = action.payload;
    },
    setList: (
      state,
      action: PayloadAction<{ list: ParcelResponseBody[]; maxCount: number }>,
    ) => {
      state.list = action.payload.list;
      state.listMeta.maxCount = action.payload.maxCount;
      state.listLoading = false;
    },
    setListItem: (state, action: PayloadAction<ParcelResponseBody>) => {
      const index = state.list.findIndex(
        (entry) => entry._id === action.payload._id,
      );
      state.list[index] = action.payload;
    },
    setColumnVisibilityModel: (
      state,
      action: PayloadAction<GridColumnVisibilityModel>,
    ) => {
      state.columnVisibilityModel = action.payload;
    },
    setListMeta: (
      state,
      action: PayloadAction<{ offset: number; limit?: number }>,
    ) => {
      state.listMeta = { ...state.listMeta, ...action.payload };
      state.zoom = initialState.zoom;
    },
    setUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.updateLoading = action.payload;
      state.updateDone = !action.payload;
    },
    resetUpdateDone: (state) => {
      state.updateDone = false;
    },
    setListFilters: (
      state,
      action: PayloadAction<
        Partial<Omit<ParcelsRequestQuery, "limit" | "offset">>
      >,
    ) => {
      state.listFilters = action.payload;
      state.zoom = initialState.zoom;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
  },
});
