import {
  ParcelHistoryResponseBody,
  ParcelResponseBody,
} from "@ero/app-common/v2/routes/models/parcel";
import { Container, Grid2, List, Typography } from "@mui/material";
import { getParcelHistory } from "Api";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HistoryItem } from "./components/historyItem/historyItem";

interface ParcelHistoryProps {
  parcel?: ParcelResponseBody | null;
}

export const ParcelHistory: React.FC<ParcelHistoryProps> = ({ parcel }) => {
  const [t] = useTranslation();

  const [parcelHistory, setParcelHistory] =
    useState<ParcelHistoryResponseBody["jobHistory"]>();

  useEffect(() => {
    const fetchParcelHistory = async () => {
      if (parcel?._id) {
        const {
          data: { jobHistory },
        } = await getParcelHistory(parcel._id);
        setParcelHistory(jobHistory);
      }
    };
    fetchParcelHistory();
  }, [parcel?._id]);

  const historyItems = useMemo(
    () => (
      <List>
        {parcelHistory?.map(
          (item: ParcelHistoryResponseBody["jobHistory"][0]) => (
            <HistoryItem item={item} key={`hi${item._id}`} />
          ),
        )}
      </List>
    ),
    [parcelHistory],
  );

  return (
    <Container maxWidth={"sm"} sx={{ minHeight: "40vh" }}>
      {historyItems}
      {parcelHistory?.length == 0 && (
        <Grid2 sx={{ textAlign: "center", marginTop: "40px" }} size={12}>
          <Typography variant={"body1"}>
            {t("parcels.jobHistory.noJobsAvailable")}
          </Typography>
        </Grid2>
      )}
    </Container>
  );
};
