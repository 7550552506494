import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { Grid2 } from "@mui/material";
import { Input } from "Components/controls";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import { useFormikContext } from "formik";
import { useAllCustomerOptions, useDropdownValues } from "Hooks";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { ParcelMap } from "./components/map/parcelMap";

export const ParcelData: React.FC = () => {
  const [t] = useTranslation();

  const { fullscreenContainer } = useFullscreenContext();

  const { setFieldValue } = useFormikContext();

  const unitOfMeasurement = useSelector(
    (state: AppState) => state.auth.companyData.unitOfMeasurement,
  );

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const { fetchDropdownValues: fetchGrapeVarietyOptions } =
    useDropdownValues("grapeVariety");

  const onSizeChange = useCallback(() => {
    setFieldValue("sizeField", SIZE_FIELD.SIZE_MANUAL);
  }, [setFieldValue]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={3} container>
        <Grid2 size={12}>
          <SelectWithAutoComplete
            name="customer"
            label={t("general.fields.customer")}
            fetchOptions={fetchCustomerOptions}
            required
            container={fullscreenContainer}
          />
        </Grid2>
        <Grid2 size={12}>
          <Input name="name" label={t("general.fields.name")} mandatory />
        </Grid2>
        <Grid2 size={12}>
          <SelectWithAutoComplete
            name="grapeVariety"
            label={t("parcels.fields.grapeVariety")}
            fetchOptions={fetchGrapeVarietyOptions}
            nullable
            container={fullscreenContainer}
          />
        </Grid2>
        <Grid2 size={6}>
          <Input
            name="size"
            type="number"
            label={t("parcels.fields.sizeUnit", {
              unit: UnitSymbol[unitOfMeasurement],
            })}
            onChange={onSizeChange}
          />
        </Grid2>
        <Grid2 size={6}>
          <Input
            name="rowAmount"
            type="number"
            label={t("parcels.fields.rowAmount")}
          />
        </Grid2>
        <Grid2 size={12}>
          <Input name="mark" label={t("parcels.fields.mark")} />
        </Grid2>
        <Grid2 size={12}>
          <Input name="regionette" label={t("parcels.fields.regionette")} />
        </Grid2>
        <Grid2 size={12}>
          <Input
            name="notes"
            label={t("general.fields.notes")}
            multiline
            rows={4}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={9}>
        <ParcelMap />
      </Grid2>
    </Grid2>
  );
};
