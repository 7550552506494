import { ParcelsRequestQuery } from "@ero/app-common/v2/routes/models/parcel";
import {
  GridApi,
  GridColumnVisibilityModel,
  GridRowId,
} from "@mui/x-data-grid";
import { BaseDataTable } from "Components/tableV2/baseDataTable/BaseDataTable";
import { TableFilterSelectWithAutoComplete } from "Components/tableV2/baseDataTable/components/FilterAutocomplete";
import { Form, Formik } from "formik";
import { useAllCustomerOptions } from "Hooks";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/parcels/parcelsSagaActions";
import { parcelsSlice } from "Store/parcels/parcelsSlice";
import { AddParcelButton } from "../addParcelButton/AddParcelButton";
import { useColumns } from "./tableConfig";

type ParcelsTableProps = {
  onRowClick: (id: number) => void;
  apiRef: React.MutableRefObject<GridApi>;
};

export const ParcelsTable: React.FC<ParcelsTableProps> = ({
  onRowClick,
  apiRef,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const columns = useColumns();

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const { list, listLoading, columnVisibilityModel, listMeta, listFilters } =
    useSelector((state: AppState) => state.parcels);

  const toolbarProps = useMemo(
    () => ({
      addButton: <AddParcelButton />,
      onDeleteClick: (deletedIds: readonly GridRowId[]) => {
        dispatch(sagaActions.deleteParcels(deletedIds as number[]));
      },
      filters: [
        <Formik
          key="parcel-filters"
          initialValues={listFilters}
          onSubmit={(values: ParcelsRequestQuery) => {
            const { customer } = values;
            dispatch(sagaActions.setFilters({ ...listFilters, customer }));
          }}
        >
          {({ submitForm }) => (
            <Form>
              <TableFilterSelectWithAutoComplete
                name="customer"
                placeholder={t("general.fields.customer")}
                fetchOptions={fetchCustomerOptions}
                onChange={submitForm}
                size="small"
                variant="standard"
                nullable
              />
            </Form>
          )}
        </Formik>,
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchCustomerOptions, listFilters, t],
  );

  const paginationModel = useMemo(
    () => ({
      page: listMeta.offset / listMeta.limit,
      pageSize: listMeta.limit,
    }),
    [listMeta.limit, listMeta.offset],
  );

  const handlePagination = useCallback((page: number, pageSize: number) => {
    dispatch(
      sagaActions.setListMeta({ limit: pageSize, offset: page * pageSize }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuickSearch = useCallback(
    (search: string) => {
      dispatch(sagaActions.setFilters({ ...listFilters, search }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listFilters],
  );

  const onColumnVisibilityModelChange = useCallback(
    (newMode: GridColumnVisibilityModel) => {
      dispatch(parcelsSlice.actions.setColumnVisibilityModel(newMode));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <BaseDataTable
      columns={columns}
      handlePagination={handlePagination}
      handleQuickSearch={handleQuickSearch}
      loading={listLoading}
      maxCount={listMeta.maxCount}
      onRowClick={onRowClick}
      rows={list}
      toolbarProps={toolbarProps}
      paginationModel={paginationModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      columnVisibilityModel={columnVisibilityModel}
      apiRef={apiRef}
    />
  );
};
