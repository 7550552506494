import { UNIT, type SqMeter } from "@ero/app-common/util/Units";
import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { convertToDuration } from "@ero/app-common/util/convertDifficulty";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Draggable } from "@fullcalendar/interaction";
import { Box, ListItem, ListItemButton, Typography } from "@mui/material";
import { AppState } from "Store";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getJobDurationInMs } from "./duration";

interface IJobItemProps {
  job: JobResponseBody;
  isNewListItem?: boolean;
  expanded?: string | false;
  handleExpandChange?: (panel?: string | false) => void;
}

export const JobItem: React.FC<IJobItemProps> = ({ job }) => {
  const wrapperRef = useRef<HTMLLIElement>(null);
  const [t] = useTranslation();
  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const duration = convertToDuration(
      getJobDurationInMs(job.parcel?.size, job.difficulty),
    );

    const settings: Draggable["settings"] = {
      eventData: {
        duration,
        id: job._id,
        title: job.name,
        originalItem: job,
        type: "job",
      },
    };
    new Draggable(wrapperRef.current, settings);
  }, [job]);

  const sizeConverted: number | undefined = useMemo(
    () =>
      (job.parcel.size &&
        convertAreaToCustom(
          job.parcel.size as SqMeter,
          unitOfMeasurement || UNIT.SQUARE_METER,
        )) ||
      undefined,
    [job.parcel.size, unitOfMeasurement],
  );

  const rowAmountText = job?.parcel.rowAmount
    ? `${job?.parcel.rowAmount} ${t("parcels.fields.rowAmount")}`
    : "";

  const sizeConvertedText = sizeConverted
    ? `${sizeConverted.toFixed(2)} ${
        UnitSymbol[unitOfMeasurement || UNIT.SQUARE_METER]
      }`
    : "";

  const displayCommaRowSize = rowAmountText && sizeConvertedText ? ", " : "";

  return (
    <ListItem ref={wrapperRef} divider disablePadding>
      <ListItemButton>
        <Box flexDirection="column" sx={{ width: "100%" }}>
          <Typography variant="subtitle2">{job.name}</Typography>
          <Typography variant="caption" component="div">
            {job.parcel.name}
          </Typography>
          {job.parcel.grapeVariety && (
            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "100%",
              }}
              variant="caption"
              component="div"
            >
              {job.parcel.grapeVariety.name}
            </Typography>
          )}
          <Typography variant="caption" component={"div"}>
            {rowAmountText}
            {displayCommaRowSize}
            {sizeConvertedText}
          </Typography>
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            variant="caption"
            component="div"
          >
            {(job.parcel.mark !== "-" || job.parcel.regionette !== "-") &&
              `${job.parcel.mark || ""}${
                job.parcel.mark && job.parcel.regionette ? ", " : ""
              }${job.parcel.regionette || ""}`}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
