import { OrdersRequestQuery } from "@ero/app-common/v2/routes/models/order";
import { Stack } from "@mui/material";
import {
  TableFilterSelectWithAutoComplete,
  TableFilterStaticSelectWithAutoComplete,
} from "Components/tableV2/baseDataTable/components/FilterAutocomplete";
import { TableFilterDateInput } from "Components/tableV2/baseDataTable/components/FilterDateInput";
import dayjs from "dayjs";
import { OrderStatus } from "Enums";
import { Form, Formik } from "formik";
import { useAllCustomerOptions } from "Hooks";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { OrderRouterState } from "../orders";

const minFilterDate = dayjs("2020");
const maxFilterDate = dayjs().add(1, "year");

type OrderFiltersProps = {
  routerState: OrderRouterState | undefined;
  setRouterState: (routerState: OrderRouterState) => void;
};

export const OrderFilters: React.FC<OrderFiltersProps> = ({
  routerState,
  setRouterState,
}) => {
  const { fetchCustomerOptions } = useAllCustomerOptions();

  const { limit } = useSelector((state: AppState) => state.orders.listMeta);

  return (
    <Formik
      key="order-filters"
      initialValues={{
        ...routerState?.filters,
        year: routerState?.filters?.ordersDateRange?.start
          ? dayjs(routerState?.filters?.ordersDateRange?.start)
          : undefined,
      }}
      onSubmit={(
        values: OrdersRequestQuery & { year: dayjs.Dayjs | undefined },
      ) => {
        const { year: _, ...filters } = values;
        setRouterState({
          filters: { ...filters, search: routerState?.filters.search },
          limit,
          offset: 0,
        });
      }}
    >
      {({ submitForm, setFieldValue }) => (
        <Form>
          <Stack direction="row" spacing={1}>
            <TableFilterSelectWithAutoComplete
              name="customer"
              placeholder={t("general.fields.customer")}
              fetchOptions={fetchCustomerOptions}
              onChange={submitForm}
              size="small"
              variant="standard"
              nullable
            />
            <TableFilterStaticSelectWithAutoComplete
              name="status"
              placeholder={t("general.fields.status")}
              options={[
                {
                  label: t("general.statuses.open"),
                  value: OrderStatus.OPEN,
                },
                {
                  label: t("general.statuses.inWork"),
                  value: OrderStatus.IN_WORK,
                },
                {
                  label: t("general.statuses.done"),
                  value: OrderStatus.DONE,
                },
              ]}
              onChange={submitForm}
              size="small"
              variant="standard"
              nullable
            />
            <TableFilterDateInput
              name="year"
              slotProps={{
                textField: { placeholder: t("general.fields.year") },
                field: { clearable: true },
              }}
              views={["year"]}
              onChange={(date) => {
                if (date && date.isValid()) {
                  setFieldValue("ordersDateRange", {
                    start: date?.startOf("year").unix() * 1000,
                    end: date?.endOf("year").unix() * 1000,
                  });
                  setFieldValue("jobsDateRange", {
                    start: date?.startOf("year").unix() * 1000,
                    end: date?.endOf("year").unix() * 1000,
                  });
                } else {
                  setFieldValue("ordersDateRange", undefined);
                  setFieldValue("jobsDateRange", undefined);
                }
                submitForm();
              }}
              minDate={minFilterDate}
              maxDate={maxFilterDate}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
