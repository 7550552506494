import { Person } from "@ero/app-common/models/Customer";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { Launch } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ROUTES } from "Constants";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const getContactDetails = (customer: GridValidRowModel) => {
  if (!customer.email && (!customer.phone || !customer.mobile)) {
    if (customer.mainContact) return customer.mainContact;
    else if (customer.persons.length > 0) return customer.persons[0];
  }
  return customer;
};

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return useMemo(
    () => [
      {
        field: "internalId",
        headerName: t("customers.fields.id"),
      },
      {
        field: "companyName",
        headerName: t("customers.fields.name"),
      },
      {
        field: "address",
        headerName: t("customers.fields.address"),
        valueGetter: (value, row) =>
          [
            ...(row.street ? [row.street] : []),
            [row.zipCode, row.city].join(" "),
          ].join(", "),
      },
      {
        field: "email",
        headerName: t("general.fields.email"),
        valueGetter: (value, row) => getContactDetails(row).email ?? "",
      },
      {
        field: "phone",
        headerName: t("general.fields.phone"),
        valueGetter: (value, row) => getContactDetails(row).phone ?? "",
      },
      {
        field: "mobile",
        headerName: t("customers.fields.mobile"),
        valueGetter: (value, row) => getContactDetails(row).mobile ?? "",
      },
      {
        field: "employees",
        headerName: t("customers.fields.persons"),
      },
      {
        field: "notes",
        headerName: t("general.fields.notes"),
      },
      {
        field: "category",
        headerName: t("customers.fields.category"),
        valueGetter: (value: { _id: number; name: string }) =>
          value._id !== -1 ? value.name : "",
      },
      {
        field: "creationDate",
        headerName: t("customers.fields.creationDate"),
        valueGetter: (value) => new Date(value).toLocaleString(),
      },
      {
        field: "mainContact",
        headerName: t("customers.fields.mainContact"),
        valueGetter: (value: Person) =>
          value._id !== -1 ? `${value.firstName} ${value.lastName}` : "",
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("general.fields.actions", { count: 2 }),
        width: 80,
        minWidth: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            key="parcels"
            label={t("general.buttons.showParcelInformation")}
            icon={
              <Tooltip title={t("general.buttons.showParcelInformation")}>
                <Launch />
              </Tooltip>
            }
            onClick={() =>
              navigate(ROUTES.MAIN.PARCELS, {
                state: { customer: params.row._id },
              })
            }
          />,
        ],
      },
    ],
    [navigate, t],
  );
};

export const defaultHiddenColumns = {
  internalId: false,
  id: false,
  creationDate: false,
  employees: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "number", access: ["internalId"], default: "-" },
  { type: "string", access: ["companyName"] },
  { type: "string", access: ["street"] },
  { type: "string", access: ["zipCode"] },
  { type: "string", access: ["city"] },
  { type: "string", access: ["email"] },
  { type: "string", access: ["phone"] },
  { type: "string", access: ["mobile"] },
  { type: "string", access: ["notes"] },
];
