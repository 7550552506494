import { type APISpec } from "@ero/app-common/routes/enforcer";
import {
  AddParcelRequestBody,
  AddParcelsRequestBody,
  ParcelHistoryResponseBody,
  ParcelResponseBody,
  ParcelsRequestQuery,
  ParcelsResponseBody,
  UpdateParcelRequestBody,
} from "@ero/app-common/v2/routes/models/parcel";
import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/parcel";
import { apiInstance, apiInstanceV2 } from "App/axios";

type Parcels_all = APISpec["/parcels/all"];
export const api_parcels_all = async (
  body: Parcels_all["body"],
  query: Parcels_all["query"],
) => {
  return apiInstance.post<Parcels_all["response"]>("/parcels/all", body, {
    params: query,
  });
};

export const getParcels = async (params: ParcelsRequestQuery) =>
  (
    await apiInstanceV2.get<ParcelsResponseBody>(baseUrl + SubUrls.ROOT, {
      params,
    })
  ).data;

export const getParcel = async (id: number) =>
  (
    await apiInstanceV2.get<ParcelResponseBody>(
      baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
    )
  ).data;

export const createParcel = async (parcel: AddParcelRequestBody) =>
  (await apiInstanceV2.post<ParcelResponseBody>(baseUrl + SubUrls.ROOT, parcel))
    .data;

export const updateParcel = async (
  id: number,
  update: UpdateParcelRequestBody,
) =>
  (
    await apiInstanceV2.patch<ParcelResponseBody>(
      baseUrl + SubUrls.BY_ID.replace(":id", id.toString()),
      update,
    )
  ).data;

export const getParcelHistory = (id: number) =>
  apiInstanceV2.get<ParcelHistoryResponseBody>(
    baseUrl + SubUrls.HISTORY.replace(":id", `${id}`),
  );

export const deleteParcels = async (ids: number[]): Promise<void> => {
  await apiInstanceV2.delete(baseUrl + SubUrls.ROOT, { params: { id: ids } });
};

export const addParcels = async (
  data: AddParcelsRequestBody,
  accessToken?: string,
) => {
  await apiInstanceV2.post(baseUrl + "/:batch", data, {
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
  });
};
