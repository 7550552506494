import { SendImportLinkRequestBody } from "@ero/app-common/v2/routes/models/import";
import {
  AddParcelRequestBody,
  AddParcelsRequestBody,
  ParcelsRequestQuery,
  UpdateParcelRequestBody,
} from "@ero/app-common/v2/routes/models/parcel";
import { createAction } from "@reduxjs/toolkit";

const fetchParcels = createAction<ParcelsRequestQuery | undefined>(
  "parcelsSaga/fetch-parcels",
);

const createParcel = createAction<{
  parcel: AddParcelRequestBody;
}>("parcelsSaga/create-parcel");

const updateParcel = createAction<{
  id: number;
  update: UpdateParcelRequestBody;
}>("parcelsSaga/update-parcel");

const setListMeta = createAction<{ limit?: number; offset: number }>(
  "parcelsSaga/set-listmeta",
);

const deleteParcels = createAction<number[]>("parcelsSaga/delete-parcels");

const sendImportLink = createAction<SendImportLinkRequestBody>(
  "parcelsSaga/send-importlink",
);

const importParcels = createAction<AddParcelsRequestBody>(
  "parcelsSaga/create-parcels",
);

const setFilters = createAction<Omit<ParcelsRequestQuery, "limit" | "offset">>(
  "parcelsSaga/set-filters",
);

export const sagaActions = {
  fetchParcels,
  createParcel,
  updateParcel,
  deleteParcels,
  setListMeta,
  sendImportLink,
  importParcels,
  setFilters,
};
