import { Warning } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type OrderDateRestrictionsWarningProps = {
  eventViolatesOrderDateRestrictions: boolean;
  eventIsMoving: boolean;
  showAllInfo: boolean;
};

export const OrderDateRestrictionsWarning: React.FC<
  OrderDateRestrictionsWarningProps
> = ({
  eventViolatesOrderDateRestrictions: eventViolatesOrderDateRestrictions,
  eventIsMoving,
  showAllInfo,
}) => {
  const [t] = useTranslation();

  if (!eventViolatesOrderDateRestrictions) {
    return <></>;
  }
  if (eventIsMoving || showAllInfo) {
    return (
      <Chip
        size="small"
        icon={<Warning />}
        color="warning"
        label={t("planningV2.businessHoursWarning")}
      />
    );
  } else {
    return (
      <Tooltip title={t("planningV2.businessHoursWarning")}>
        <Warning fontSize="small" htmlColor="yellow" />
      </Tooltip>
    );
  }
};
