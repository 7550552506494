import { Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type TimeProps = {
  start: Date | undefined;
  end: Date | undefined;
};

export const Time: React.FC<TimeProps> = ({ start, end }) => {
  const [_, i18n] = useTranslation();

  const { startTime, endTime } = useMemo(
    () => ({
      startTime: start
        ? new Intl.DateTimeFormat(i18n.language, {
            timeStyle: "short",
          }).format(start)
        : "",
      endTime: end
        ? new Intl.DateTimeFormat(i18n.language, {
            timeStyle: "short",
          }).format(end)
        : "",
    }),
    [i18n.language, start, end],
  );

  const isScheduled = useMemo(() => start && end, [end, start]);

  if (!isScheduled) {
    return <></>;
  }

  return (
    <Grid2 size={12}>
      <Typography variant="body2">
        {startTime} - {endTime}
      </Typography>
    </Grid2>
  );
};
