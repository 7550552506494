import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { importParcels } from "Api/import";
import { NotificationService } from "Services";
import { FieldImportValuesType } from "Types";

const getBody = (values: FieldImportValuesType) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return formData;
};

export const useUploadProgress = (
  onSuccess: (responseData: ImportResponseBody) => void,
  externalAccessToken?: string,
  showNotification?: boolean,
) => {
  const [t] = useTranslation();

  const [progress, setProgress] = useState<number>();
  const [isUploading, setIsUploading] = useState<boolean>();

  const onUploadProgress = useCallback(
    ({ loaded, total, lengthComputable }) => {
      if (lengthComputable) {
        const ratio = loaded / total;
        const percent = Math.round(ratio * 100);
        setProgress(percent);
      }
    },
    [],
  );

  const showSuccess = useCallback(() => {
    NotificationService.success(
      t("notification.successTitle"),
      t("notification.success.parcelimport"),
    );
  }, [t]);

  const showError = useCallback(() => {
    NotificationService.error(
      t("notification.errorTitle"),
      t("notification.error.parcelimport"),
    );
  }, [t]);

  const uploadFiles = async (values: FieldImportValuesType) => {
    setIsUploading(true);
    try {
      const body = getBody(values);
      const result = await importParcels(
        body,
        onUploadProgress,
        externalAccessToken,
      );
      if (result.status != 200) {
        showError();
      } else {
        const responseData = await result.data;
        onSuccess(responseData);
        if (showNotification) {
          showSuccess();
        }
      }
    } catch (_) {
      showError();
    }
    setIsUploading(false);
  };

  return {
    progress,
    isUploading,
    uploadFiles,
  };
};
