import { alpha, Box, Paper, useTheme } from "@mui/material";
import { useAuthBackground } from "Hooks/useAuthBackground";
import React, { PropsWithChildren } from "react";

export const PublicLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const backgroundImage = useAuthBackground();

  const theme = useTheme();

  return (
    <Box
      display="flex"
      position="fixed"
      alignItems="center"
      justifyContent="center"
      top="0px"
      left="0px"
      height="100vh"
      width="100vw"
      overflow="auto"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <Paper
        elevation={5}
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
          width: 470,
          borderRadius: 5,
          padding: { xs: "15px 15px", md: "20px 20px" },
          backdropFilter: "blur(5px);",
        }}
      >
        <Box display="flex" flexDirection="column" boxSizing="border-box">
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
