import { MarkerClustererF } from "@react-google-maps/api";
import {
  CenterControlV2,
  FullScreenControlV2,
  MapParcel,
  MapV2,
  ZoomControlV2,
} from "Components";
import {
  BottomControls,
  TopControls,
} from "Components/map/components/controls";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { parcelsSlice } from "Store/parcels/parcelsSlice";

const PARCEL_CLUSTER_MAXZOOM = 16;

type ParcelsMapProps = {
  onParcelClick: (id: number) => void;
};

export const ParcelsMap: React.FC<ParcelsMapProps> = ({ onParcelClick }) => {
  const dispatch = useDispatch();

  const map = useRef<google.maps.Map>();

  /*
   * Keep track of the previous parcel bounds, so that we can later
   * on only fit the map to the bounds, when they changed, i.e. after
   * pagination, search ... but not after editing a single parcel
   */
  const previousBoundsRef = useRef<google.maps.LatLngBounds>();

  const { machinePosition } = useSelector(
    (state: AppState) => state.auth.companyData,
  );
  const { list, zoom } = useSelector((state: AppState) => state.parcels);

  const showPolygon = useMemo(
    () => (zoom ? zoom > PARCEL_CLUSTER_MAXZOOM : false),
    [zoom],
  );

  const coordinates = useMemo(
    () =>
      list
        .map((p) => p.shape || p.position)
        .flat()
        .filter((c) => !!c),
    [list],
  );

  const currentBounds = useMemo(() => {
    if (google.maps.LatLngBounds) {
      const bounds = new google.maps.LatLngBounds();
      coordinates.forEach((c) => bounds.extend(c));
      if (machinePosition) {
        bounds.extend(machinePosition);
      }
      return bounds;
    }
  }, [coordinates, machinePosition]);

  const clusteredParcels = useMemo(
    () => (
      <MarkerClustererF
        options={{
          maxZoom: PARCEL_CLUSTER_MAXZOOM,
          averageCenter: true,
        }}
      >
        {(clusterer) => (
          <>
            {list.map((p) => (
              <MapParcel
                key={p._id}
                parcel={p}
                showPolygon={showPolygon}
                markerProps={{ clusterer }}
                onClick={() => onParcelClick(p._id)}
              />
            ))}
          </>
        )}
      </MarkerClustererF>
    ),
    [list, onParcelClick, showPolygon],
  );

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      map.current = mapInstance;
      if (currentBounds) map.current.fitBounds(currentBounds);
    },
    [currentBounds],
  );

  const resetCenter = useCallback(() => {
    if (machinePosition) {
      map.current?.setCenter(machinePosition);
    } else {
      if (currentBounds) map.current?.fitBounds(currentBounds);
    }
  }, [currentBounds, machinePosition]);

  const onZoomChanged = useCallback((zoom?: number) => {
    if (zoom) {
      map.current?.setZoom(zoom);
      dispatch(parcelsSlice.actions.setZoom(zoom));
    } else {
      const newZoom = map.current?.getZoom();
      if (newZoom) {
        dispatch(parcelsSlice.actions.setZoom(newZoom));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      google.maps.LatLngBounds &&
      currentBounds &&
      !previousBoundsRef.current?.equals(currentBounds)
    ) {
      // directly calling fitBounds can lead to race conditions with removing parcel polygons
      // from the map (due to zoom change and removing react components vs. the way,
      // react-google-maps/api handles the SDK), so give the parcels time to re-render before
      // fitting the map to the new bounds
      setTimeout(() => void map.current?.fitBounds(currentBounds), 100);
      previousBoundsRef.current = currentBounds;
    }
  }, [currentBounds]);

  return (
    <MapV2
      id="parcel-map"
      center={machinePosition}
      zoom={zoom}
      onLoad={onLoad}
      onZoomChanged={onZoomChanged}
    >
      {clusteredParcels}
      <TopControls>
        <FullScreenControlV2 />
      </TopControls>
      <BottomControls>
        <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
        <ZoomControlV2 zoom={zoom} onZoomChanged={onZoomChanged} />
      </BottomControls>
    </MapV2>
  );
};
