import {
  AddEventRequestBody,
  UpdateEventRequestBody,
} from "@ero/app-common/v2/routes/models/event";
import { AddOrderRequestBody } from "@ero/app-common/v2/routes/models/order";
import { createAction } from "@reduxjs/toolkit";
import { FilterType } from "./planningV2slice";

const fetchUsers = createAction("planningV2saga/fetch-users");

const fetchOrders = createAction("planningV2saga/fetch-orders");

const setSelectedUsers = createAction<number[]>(
  "planningV2saga/set-selectedusers",
);

const fetchEvents = createAction("planningV2saga/fetch-events");

const setDate = createAction<number>("planningV2saga/set-date");

const addEvent = createAction<{
  event: AddEventRequestBody;
  revert: () => void;
}>("planningV2saga/add-event");

const updateEvent = createAction<{
  id: number;
  update: UpdateEventRequestBody;
  revert?: () => void;
}>("planningV2saga/update-event");

const updateEvents = createAction<{
  events: {
    id: number;
    update: UpdateEventRequestBody;
  }[];
  revert: () => void;
}>("planningV2saga/update-events");

const deleteEvent = createAction<{ ids: number[] }>(
  "planningV2saga/delete-event",
);

const setFilters = createAction<FilterType>("planningV2saga/set-filters");

const setSlotInterval = createAction<number>("planningV2saga/set-slotinterval");

const createOrder = createAction<AddOrderRequestBody>(
  "planningV2saga/create-order",
);

export const sagaActions = {
  fetchUsers,
  setSelectedUsers,
  fetchOrders,
  fetchEvents,
  setDate,
  addEvent,
  updateEvent,
  updateEvents,
  deleteEvent,
  setFilters,
  setSlotInterval,
  createOrder,
};
